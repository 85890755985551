import React from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from '../utils/i18n';

/**
 * Wraps a component with a Tooltip.  By default, if the wrapped component
 * doesn’t receive children, the HOC will render `defaultContent` inside it.
 */
function withTooltip<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  tooltipTextKey: string,
  defaultContent?: React.ReactNode
) {
  // Merge the wrapped component props with an optional `children`.
  type PropsWithChildren = P & { children?: React.ReactNode };

  const ComponentWithTooltip: React.FC<PropsWithChildren> = (props) => {
    const t = useTranslation();
    const { children, ...restProps } = props;

    return (
      <Tooltip title={t(tooltipTextKey)}>
        {/* 
          Wrapping with <span> so that MUI can anchor the tooltip properly 
          (it needs a single, non-display:none element). 
        */}

        <WrappedComponent {...(restProps as P)}>{children ?? defaultContent}</WrappedComponent>
      </Tooltip>
    );
  };

  // For clearer debugging in DevTools
  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithTooltip.displayName = `withTooltip(${wrappedComponentName})`;

  return ComponentWithTooltip;
}

export default withTooltip;
