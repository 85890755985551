/// <reference types="vite/client" />

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Reader } from './components/Reader';
import { ThemeProvider, type Theme } from '@mui/material/styles';
import BookList from './components/BookList';
import HandleShare from './components/HandleShare';
import { ErrorBoundary } from 'react-error-boundary';
import React, { useEffect, useState, useContext } from 'react';
import { chooseTheme } from './theme';
import { AppContext, AppProvider } from './context/AppContext';
import { Snackbar } from '@mui/material';
import Loading from './components/Loading';
import { TranslationsProvider } from './utils/i18n';
import { ShepherdJourneyProvider } from 'react-shepherd';
import LoginPage from './components/LoginPage';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  React.useEffect(() => {
    // Automatically reset the error boundary and reload the page
    if (resetErrorBoundary) {
      setTimeout(() => {
        resetErrorBoundary();
        window.location.reload();
      }, 5000);
    }
  }, [resetErrorBoundary]);

  return (
    <div role='alert'>
      <p>Crap. Hang on, reloading the page...</p>
      <pre>{error.message}</pre>
    </div>
  );
}

const ThemedApp = () => {
  const { effectiveTheme, snackbarMessage, setSnackbarMessage, localForageLoaded, user } = useContext(AppContext);
  //   const preference = matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  const [theme, setTheme] = useState<Theme | undefined>();

  useEffect(() => {
    if (effectiveTheme) {
      setTheme(chooseTheme(effectiveTheme));
    }
  }, [effectiveTheme]);

  useEffect(() => {
    const handleSnackbarMessage = (event: CustomEvent) => {
      setSnackbarMessage(event.detail);
    };

    window.addEventListener('snackbarMessage', handleSnackbarMessage as EventListener);

    return () => {
      window.removeEventListener('snackbarMessage', handleSnackbarMessage as EventListener);
    };
  }, [setSnackbarMessage]);

  return localForageLoaded && theme ? (
    <ThemeProvider theme={theme}>
      <div className='relative w-full bg-stone-100 dark:bg-black' style={{ margin: 0 }}>
        <div className='max-w-6xl mx-auto grid grid-cols-1' style={{ margin: 0 }}>
          <main>
            <Routes>
              <Route
                path='/handle_epub'
                element={<HandleShare setSnackbarMessage={setSnackbarMessage} user={user} />}
              />
              {/* Protected routes - require user to be logged in */}
              <Route path='/:cmd?' element={user ? <BookList /> : <Navigate to='/login' replace />} />{' '}
              {/* Login gate here */}
              <Route
                path='/read/:id/:uriLocation?'
                element={user ? <Reader /> : <Navigate to='/login' replace />}
              />{' '}
              {/* Login gate here */}
              {/* Public login route */}
              <Route path='/login' element={user ? <Navigate to='/' replace /> : <LoginPage />} />
            </Routes>
          </main>
        </div>
      </div>
      {Boolean(snackbarMessage) && (
        <Snackbar
          open={Boolean(snackbarMessage)}
          autoHideDuration={snackbarMessage?.duration || 10000}
          onClose={() => setSnackbarMessage(null)}
          message={snackbarMessage?.text}
        />
      )}
    </ThemeProvider>
  ) : (
    <Loading />
  );
};

const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset the state of your application so the error doesn't happen again
      }}
    >
      <AppProvider>
        <TranslationsProvider>
          <ShepherdJourneyProvider>
            <BrowserRouter basename={import.meta.env.BASE_URL}>
              <ThemedApp />
            </BrowserRouter>
          </ShepherdJourneyProvider>
        </TranslationsProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};

export default App;
