interface FontConfig {
  name: string;
  params: string;
}

export const googleFonts: FontConfig[] = [
  //   { name: "Lora", params: "ital,wght@0,400..700;1,400..700" },
  { name: 'Lora', params: 'ital,wght@0,400;0,700;1,400;1,700' },
  { name: 'Petrona', params: 'ital,wght@0,100..900;1,100..900' },
  {
    name: 'Merriweather',
    params: 'ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900',
  },
  //   { name: "Martel", params: "wght@200;300;400;600;700;800;900" },
  //   { name: 'Martel', params: 'wght@300;400;700;900' },
  //   { name: "Newsreader", params: "ital,opsz,wght@0,6..72,200..800;1,6..72,200..800" },
  {
    name: 'Newsreader',
    params: 'ital,opsz,wght@0,6..72,300..700;1,6..72,300..700',
  },
  //   { name: "Alegreya", params: "ital,wght@0,400..900;1,400..900" },
  //   { name: "Bitter", params: "ital,wght@0,100..900;1,100..900" },
  //   { name: "Crimson Pro", params: "ital,wght@0,200..900;1,200..900" },
  //   { name: "Labrada", params: "ital,wght@0,100..900;1,100..900" },
  //   { name: "Lato", params: "ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900" },
  { name: 'Literata', params: 'ital,opsz,wght@0,7..72,200..900;1,7..72,200..900' },
  //   { name: "Noticia Text", params: "ital,wght@0,400;0,700;1,400;1,700" },
  //   { name: "Noto Serif", params: "ital,wght@0,100..900;1,100..900" },
  //   { name: "Playfair", params: "ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900" },
  //   { name: 'Inter', params: 'ital,opsz,wght@0,14..32,100..900;1,14..32,100..900' },
  { name: 'Roboto Flex', params: 'opsz,wght@8..144,100..900' },
  { name: 'Noto Sans', params: 'ital,wght@0,100..900;1,100..900' },
  { name: 'Georama', params: 'ital,wght@0,100..900;1,100..900' },
  { name: 'Urbanist', params: 'ital,wght@0,100..900;1,100..900' },
  //   { name: 'Glory', params: 'ital,wght@0,100..800;1,100..800' },
  { name: 'Bricolage Grotesque', params: 'opsz,wght@12..96,200..800' },
  //   { name: "Wix Madefor Display", params: "wght@400..800" },
  //   { name: 'Marcellus', params: '' },
  { name: 'Quicksand', params: 'wght@300..700' },
  { name: 'Lato', params: 'ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700' },
  //   { name: "Shantell Sans", params: "ital,wght@0,300..800;1,300..800" },
  //   { name: "Vollkorn", params: "ital,wght@0,400..900;1,400..900" },
  //   { name: "Yrsa", params: "ital,wght@0,300..700;1,300..700" },
];

export const getFontList = (): string[] => {
  return ['default', ...googleFonts.map((font) => font.name), 'serif', 'sans-serif'];
};

export const generateGoogleFontsUrl = (fonts: FontConfig[]) => {
  const fontFamilies = fonts
    .map((font) => `family=${font.name.replace(' ', '+')}${font.params ? `:${font.params}` : ''}`)
    .join('&');
  return `https://fonts.googleapis.com/css2?${fontFamilies}&display=swap`;
};

export const generateGoogleFontsLink = (fonts: FontConfig[]) => {
  const url = generateGoogleFontsUrl(fonts);
  return `<link href="${url}" rel="stylesheet" />`;
};
