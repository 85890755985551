import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Button,
  Avatar,
  Stack,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  type ButtonProps,
  DialogContent,
  DialogTitle,
  Dialog,
  CircularProgress,
} from '@mui/material';
import { Refresh as RefreshIcon, CloudOff as CloudOffIcon } from '@mui/icons-material';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, PrevButton, NextButton } from './CarouselButtons';
import {
  getBooks,
  archiveBook,
  logoutUser,
  fetchUser,
  fetchFollowing,
  getBookProgressAndNotes,
  getStreaks,
  aggregateWeeklyTime,
  addBookFromFile,
  replaceBookFile,
  deleteBook,
  upgrade,
  getTheme,
  updateBooksCache,
  getMisc,
  updateCurrentUser,
  updateFollowing,
  updateMisc,
  updateNotesStatus,
  getBook,
  resetBook,
  addTranslatedVersion,
  addDefaultBooks,
} from '../utils/books';
import type { BookMeta } from '../types/book';
import BookCover from './BookCover';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { saveFile, getDB } from '../utils/indexedDB';
import ReadingChart from './ReadingChart';
import dayjs from 'dayjs';
import { currentStoreVersion, LANGUAGES } from '../config';
import { debounce } from '../utils/core';
import { version } from '../../package.json';
import { AppContext } from '../context/AppContext';
import { Changelog } from './Changelog';
import localforage from 'localforage';
import { TranslationsContext, useTranslation } from '../utils/i18n';
import withTooltip from './withTooltip';
import { useShepherd } from 'react-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

/**
 * A helper component that automatically starts the tour if the user is not logged in
 * and the tour has not yet been seen.
 */
const TourStarter: React.FC<{ user: any }> = ({ user }) => {
  const Shepherd = useShepherd();
  const t = useTranslation();

  useEffect(() => {
    const checkAndStartTour = async () => {
      const tourSeen = await localforage.getItem('tourSeenLogin');
      if (!user && !tourSeen) {
        const tour = new Shepherd.Tour({
          useModalOverlay: true,
        });
        // Define a tour step (you can add more steps as needed)
        tour.addStep({
          id: 'login-step',
          title: t('GET_STARTED'),
          text: t('TOUR_LOGIN'),
          attachTo: { element: '.login-button', on: 'bottom' },
          buttons: [
            {
              text: t('TOUR_GOT_IT'),
              action: () => {
                tour.complete();
                localforage.setItem('tourSeenLogin', true);
              },
            },
          ],
        });
        tour.start();
      }
    };

    checkAndStartTour();
  }, [user, Shepherd, t]);

  return null;
};

const clientId = '23573608440-367kogg7qm9p84ai8g82miac8pshhkse.apps.googleusercontent.com';

const AddBookButton = withTooltip<ButtonProps>(Button, 'ADD_BOOK_BUTTON_HELP');
const LogoutButton = withTooltip<ButtonProps>(Button, 'LOGOUT_BUTTON_HELP');
const LoginButton = withTooltip<ButtonProps>(Button, 'LOGIN_BUTTON_HELP');
const BackupButton = withTooltip<ButtonProps>(Button, 'BACKUP_BUTTON_HELP');
const RestoreButton = withTooltip<ButtonProps>(Button, 'RESTORE_BUTTON_HELP');
const VersionButton = withTooltip<ButtonProps>(Button, 'VERSION_BUTTON_HELP');
const LanguageButton = withTooltip<ButtonProps>(Button, 'LANGUAGE_BUTTON_HELP');

const BookList: React.FC = () => {
  const { currentLanguage, isLoading, setLanguage } = useContext(TranslationsContext);
  const t = useTranslation();

  const { setSnackbarMessage, checkAppVersion, isOnline, localForageLoaded, prefs, changePrefs, user, setUser } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [replaceFileId, setReplaceFileId] = useState<string | null>(null);
  const [progressInfo, setProgressInfo] = useState<{ [hash: string]: any }>({});
  const [notesInfo, setNotesInfo] = useState<{ [hash: string]: Record<string, number> }>({});
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const translatedFileInputRef = useRef<HTMLInputElement | null>(null);
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [bookFilter, setBookFilter] = useState<string>('active');
  const [books, setBooks] = useState<{ [id: string]: BookMeta }>({});
  let translatedVersionId = '';

  // Embla Carousel setup
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [streaks, setStreaks] = useState<{
    [id: string]: { days: number; today: boolean };
  }>({});
  const [weeklyTime, setWeeklyTime] = useState<{ label: string; value: number }[]>([]);
  const [streakTime, setStreakTime] = useState<{ label: string; value: number }[]>([]);
  const [monthlyTime, setMonthlyTime] = useState<{ label: string; value: number }[]>([]);

  const { cmd } = useParams<{ cmd: string }>(); // Assuming dynamic routing based on book ID

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  // For the language picker modal
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(currentLanguage);

  // Add this ref to track initialization status
  const isInitializing = useRef(false);

  const filteredBooks = useMemo(() => {
    if (!localForageLoaded) return [];
    return Object.entries(books)
      .filter(([id, book]) => {
        if (bookFilter.toLowerCase() === 'active') return book.location === 'active';
        if (bookFilter.toLowerCase() === 'archived') return book.location === 'archived';
        if (bookFilter.toLowerCase() === 'all') return book.location !== 'trash';
        return false;
      })
      .sort((a, b) => (b[1].timestamp || 0) - (a[1].timestamp || 0));
  }, [books, bookFilter, localForageLoaded]);

  const charts = useMemo(
    () => [
      {
        id: 'weekly',
        label: t('WEEKLY_TIME'),
        component: <ReadingChart data={weeklyTime} isAnimationActive={true} animationDuration={3000} />,
      },
      {
        id: 'monthly',
        label: t('MONTHLY_TIME'),
        component: (
          <ReadingChart
            data={monthlyTime}
            isAnimationActive={true}
            dot={false}
            animationDuration={3000}
            movingAverageWindow={7}
          />
        ),
      },
      {
        id: 'streak',
        label: t('STREAK_TIME'),
        component: (
          <ReadingChart
            data={streakTime}
            dot={false}
            isAnimationActive={true}
            animationDuration={3000}
            movingAverageWindow={
              streakTime.length >= 180
                ? 30
                : streakTime.length >= 60
                ? 14
                : streakTime.length >= 30
                ? 7
                : streakTime.length >= 7
                ? 3
                : undefined
            }
          />
        ),
      },
    ],
    [weeklyTime, monthlyTime, streakTime, t]
  );

  const sortBooks = (books: BookMeta[]): BookMeta[] => {
    return books.sort((a, b) => {
      const timeDiff = (b.timestamp || 0) - (a.timestamp || 0);
      if (timeDiff !== 0) return timeDiff;
      return (a.title || '').localeCompare(b.title || '');
    });
  };

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
      });
    }
  }, [emblaApi]);

  useEffect(() => {
    if (!localForageLoaded || isInitializing.current) return;

    const initializeBooks = async () => {
      // Set initializing flag
      isInitializing.current = true;

      try {
        // Check if this is a new user (no books)
        const existingBooks = getBooks();
        if (Object.keys(existingBooks).length === 0) {
          setSnackbarMessage({
            text: t('ADDING_STARTER_BOOKS'),
            duration: null,
          });
          await addDefaultBooks();
          refreshBookList();
          setSnackbarMessage({
            text: t('ADDED_DEFAULT_BOOKS'),
            duration: 5000,
          });
        } else {
          setBooks(existingBooks);
        }
      } catch (error) {
        console.error('Error initializing books:', error);
        setSnackbarMessage({
          text: t('ERROR_LOADING_BOOKS'),
          duration: 5000,
        });
      } finally {
        // Reset initializing flag
        isInitializing.current = false;
      }
    };

    initializeBooks();
  }, [localForageLoaded]);

  useEffect(() => {
    if (!localForageLoaded) return;
    const debouncedUpgrade = debounce(upgrade, 1000);
    setTimeout(() => {
      const oldStoreVersion = getMisc()?.storeVersion || currentStoreVersion;
      if (oldStoreVersion < currentStoreVersion) {
        console.debug('oldVersion', oldStoreVersion, 'current version', currentStoreVersion);
        setSnackbarMessage({ text: t('UPGRADING_INTERNALS_WAIT'), duration: null });

        debouncedUpgrade().then(() => {
          const storeVersion = getMisc()?.storeVersion || currentStoreVersion;
          console.log('version and old', storeVersion, oldStoreVersion);
          if (storeVersion !== oldStoreVersion) {
            console.log('upgraded to version', storeVersion);
            setSnackbarMessage({
              text: t('STORAGE_UPGRADED_RELOADING', { storeVersion: storeVersion }),
              duration: 5000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        });
      }
    }, 1000);
    return () => {
      debouncedUpgrade.cancel();
    };
  }, [localForageLoaded]);

  useEffect(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, []);

  useEffect(() => {
    if (cmd !== 'no-upgrade') {
      checkAppVersion();
    } else {
      console.log('not checking version');
      // change the apparent URL back to default
      const url = new URL(window.location.href);
      url.pathname = import.meta.env.BASE_URL;
      window.history.pushState({ path: url.href }, '', url.href);
    }
  }, [checkAppVersion, cmd]);

  const getStreakEmoji = (today: boolean): string => {
    if (today) return '🔥';

    const now = dayjs();
    const midnight = now.endOf('day');
    const hoursUntilMidnight = midnight.diff(now, 'hour');

    // Get clock emoji based on current hour
    const hour = now.hour();
    const clockEmoji = ['🕛', '🕐', '🕑', '🕒', '🕓', '🕔', '🕕', '🕖', '🕗', '🕘', '🕙', '🕚'][hour % 12];

    // if (hoursUntilMidnight > 18) return `😊 ${clockEmoji}`;
    // if (hoursUntilMidnight > 15) return `🙂 ${clockEmoji}`;
    // if (hoursUntilMidnight > 12) return `😐 ${clockEmoji}`;
    // if (hoursUntilMidnight > 10) return `😕 ${clockEmoji}`;
    // if (hoursUntilMidnight > 8) return `😟 ${clockEmoji}`;
    // if (hoursUntilMidnight > 6) return `😔 ${clockEmoji}`;
    // if (hoursUntilMidnight > 5) return `😞 ${clockEmoji}`;
    // if (hoursUntilMidnight > 4) return `😢 ${clockEmoji}`;
    // if (hoursUntilMidnight > 3) return `😰 ${clockEmoji}`;
    // if (hoursUntilMidnight > 2) return `😭 ${clockEmoji}`;
    // if (hoursUntilMidnight > 1) return `😨 ${clockEmoji}`;
    // return `😱😱😱 ${clockEmoji}`;
    return clockEmoji;
  };

  /**
   * Sorts a book object by converting it to an array, sorting the array, and then converting it back to an object.
   * @param bookObject - The object containing book metadata, keyed by book ID.
   * @returns A new object with the books sorted by their metadata.
   */
  const sortBookObject = (bookObject: { [id: string]: BookMeta }): { [id: string]: BookMeta } => {
    const bookArray: BookMeta[] = Object.values(bookObject);
    const sortedBooks: BookMeta[] = sortBooks(bookArray);
    return sortedBooks.reduce((obj: { [id: string]: BookMeta }, book: BookMeta) => {
      obj[book.id] = book;
      return obj;
    }, {} as { [id: string]: BookMeta });
  };

  // Load progress info when books change
  useEffect(() => {
    const loadProgressAndNotes = async () => {
      const hashes = Object.values(books)
        .map((book) => book.hash)
        .filter((hash): hash is string => hash !== undefined);

      const progress = await getBookProgressAndNotes(hashes);

      setProgressInfo(progress);
    };

    if (localForageLoaded && books && Object.keys(books).length > 0) {
      loadProgressAndNotes();
    }
  }, [books, localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded) {
      fetchFollowing();
    }
  }, [localForageLoaded]);

  const refreshBookList = () => {
    console.info('refreshing book list');
    const booksObject = getBooks();
    const sortedBooks = sortBookObject(booksObject);

    setBooks(sortedBooks);
    updateBooksCache(sortedBooks);
  };

  //   useEffect(() => {
  //     if (user && window.location.href.includes('authed')) {
  //       window.location.href = import.meta.env.BASE_URL;
  //       console.log('redirecting to', import.meta.env.BASE_URL);
  //     }
  //   }, [user]);

  useEffect(() => {
    const authed = async () => {
      console.log('authed, cmd=', cmd, ' user=', user);
      window.location.href = import.meta.env.BASE_URL;
      console.log('redirecting to', import.meta.env.BASE_URL);
      //   const u = await fetchUser(true);

      //   setUser(u);
      //   console.log('user set to', u);
    };

    if (cmd === 'authed') {
      authed();
    } else if (cmd === 'badurl') {
      // TODO: implement
    } else if (cmd) {
      console.log('cmd = ', cmd);
    }
  }, [cmd]);

  useEffect(() => {
    if (!localForageLoaded) return;
    const loadStreaks = async () => {
      if (user?.email) {
        const streaks = await getStreaks([user.email]);
        if (streaks) {
          setStreaks(streaks);

          const streakTime = await aggregateWeeklyTime(streaks[user.email].days, 'dayOfMonth');
          setStreakTime(streakTime);
        }
      }
    };
    const updateWeeklyTime = async () => {
      const time = await aggregateWeeklyTime(7, 'dayOfWeek');
      setWeeklyTime(time);
    };
    const updateMonthlyTime = async () => {
      const time = await aggregateWeeklyTime(30, 'dayOfMonth');
      setMonthlyTime(time);
    };

    console.log('loading streaks from BookList for ', user?.email);
    if (user?.email) {
      loadStreaks();
      updateWeeklyTime();
      updateMonthlyTime();
    }
  }, [user?.email, localForageLoaded]);

  const createBackupZip = async () => {
    const zip = new JSZip();

    // Add localforage data to the zip file
    const localForageData: { [key: string]: any } = {};
    await localforage.iterate((value, key) => {
      localForageData[key] = value;
    });
    const localForageBlob = new Blob([JSON.stringify(localForageData)], {
      type: 'application/json',
    });
    zip.file('localForageBackup.json', localForageBlob);

    // Add IndexedDB files to the zip file
    const db = await getDB();
    const transaction = db.transaction(['ePubFiles'], 'readonly');
    const ePubFilesStore = transaction.objectStore('ePubFiles');

    const ePubFilesKeys = await ePubFilesStore.getAllKeys();

    const metadataStore: { [key: string]: any } = {};

    // Helper function to get all files and their metadata
    const getAllFiles = async (store: any, keys: any, folderName: any) => {
      for (const key of keys) {
        const file = await store.get(key);
        if (file) {
          zip.file(`${folderName}/${key}`, file);

          // If the file is not a cover, save its metadata
          if (!key.startsWith('cover-')) {
            metadataStore[key] = {
              name: file.name,
              type: file.type,
              lastModified: file.lastModified,
            };
          }
        }
      }
    };

    // Wait for all files to be added to the zip
    await getAllFiles(ePubFilesStore, ePubFilesKeys, 'ePubFiles');

    // Add metadata to the zip file
    const metadataBlob = new Blob([JSON.stringify(metadataStore)], {
      type: 'application/json',
    });
    zip.file('metadataBackup.json', metadataBlob);

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Save or share the zip file
    const saveOrShareZip = async (blob: Blob) => {
      if (navigator.share) {
        const file = new File([blob], 'backup.zip', {
          type: 'application/zip',
        });
        try {
          await navigator.share({
            files: [file],
            title: t('BACKUP_TITLE'),
            text: t('BACKUP_TEXT'),
          });
          console.log('Shared successfully');
        } catch (error) {
          console.error('Error sharing', error);
          saveAs(blob, 'backup.zip');
        }
      } else {
        saveAs(blob, 'backup.zip');
      }
    };

    saveOrShareZip(zipBlob);
  };

  const loadFromBackup = async (file: File) => {
    try {
      const zip = await JSZip.loadAsync(file);

      // Load localforage data from the zip file
      const localForageFile = zip.file('localForageBackup.json');
      if (localForageFile) {
        const localForageBackup = await localForageFile.async('text');
        const localForageData = JSON.parse(localForageBackup);
        for (const key in localForageData) {
          const data = localForageData[key];
          console.debug('loading key', key, data);
          if (key === 'books') {
            updateBooksCache(data);
          } else if (key === 'user') {
            updateCurrentUser(data);
          } else if (key === 'following') {
            updateFollowing(data);
          } else if (key === 'misc') {
            updateMisc(data);
          } else if (key === 'notesStatus') {
            updateNotesStatus(data);
          } else {
            console.warn('unsupported key in localForageBackup.json:', key);
            await localforage.setItem(key, data);
          }
        }
        console.log('localforage reloaded successfully');
      } else {
        console.warn('localForageBackup.json not found in the zip');
        setSnackbarMessage({ text: t('LOCALFORAGE_BACKUP_NOT_FOUND'), duration: 3000 });
        const localStorageFile = zip.file('localStorageBackup.json');
        if (localStorageFile) {
          const localStorageBackup = await localStorageFile.async('text');
          const localStorageData = JSON.parse(localStorageBackup);
          for (const key in localStorageData) {
            localStorage.setItem(key, localStorageData[key]);
          }
          console.log('localStorage reloaded successfully');
        }
      }

      // Load metadata from the zip file
      const metadataFile = zip.file('metadataBackup.json');
      let metadataStore: { [key: string]: any } = {};
      if (metadataFile) {
        const metadataBackup = await metadataFile.async('text');
        metadataStore = JSON.parse(metadataBackup);
      } else {
        console.warn('metadataBackup.json not found in the zip');
      }

      // Load IndexedDB data from the zip file
      const ePubFilesFolder = zip.folder('ePubFiles');

      if (ePubFilesFolder) {
        console.log('Processing ePubFiles...');
        ePubFilesFolder.forEach(async (relativePath, file) => {
          console.log(`Reading file: ${relativePath}`);
          const fileContent = await file.async('blob');
          if (fileContent) {
            if (relativePath.startsWith('cover-')) {
              // Save as Blob for covers
              console.log(`Saving cover blob ${relativePath} to IndexedDB`);
              await saveFile(
                relativePath,
                fileContent.type ? fileContent : new Blob([fileContent], { type: 'image/jpeg' })
              );
            } else {
              // Save as File for EPUB files
              const metadata = metadataStore[relativePath];
              let fileObject;
              if (metadata) {
                fileObject = new File([fileContent], metadata.name, {
                  type: metadata.type,
                  lastModified: metadata.lastModified,
                });
              } else {
                fileObject = new File([fileContent], file.name, {
                  type: fileContent.type || 'application/epub+zip',
                  lastModified: new Date().getTime(),
                });
              }
              console.log(`Saving file ${relativePath} to IndexedDB`);
              await saveFile(relativePath, fileObject);
            }
          } else {
            console.warn(`Failed to read file content for ${relativePath}`);
          }
        });
        console.log('ePubFiles reloaded successfully');
      } else {
        console.warn('No ePubFiles folder found in the zip');
      }

      console.log('Backup loaded successfully.');
    } catch (error) {
      console.error('Error loading backup:', error);
    }
  };

  // TODO: refreshbooklist after adding books
  // TODO: snackbar on error adding books

  const handleLoadBackup = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSnackbarMessage({ text: t('NO_FILES_SELECTED_YOU_DO_YOU'), duration: 3000 });
      return;
    }
    const file = e.target.files[0];
    setSnackbarMessage({ text: t('LOADING_BACKUP_WAIT'), duration: null });
    await loadFromBackup(file);
    setSnackbarMessage({ text: t('BACKUP_LOADED_SUCCESSFULLY'), duration: 3000 });
    refreshBookList();
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSnackbarMessage({ text: t('NO_FILES_SELECTED_BUT_YOU_DO_YOU'), duration: 3000 });
      return;
    }

    const handleMultipleFileUpload = async () => {
      if (!files) {
        return;
      }

      const uploadPromises = Array.from(files).map((file) => addBookFromFile(file));
      try {
        setSnackbarMessage({ text: t('ADDING_BOOKS'), duration: null });
        const addedBooks = await Promise.all(uploadPromises);
        const validBooks = addedBooks.filter(Boolean);

        if (validBooks.length === 0) {
          setSnackbarMessage({
            text: t('EVERY_BOOK_FAILED_TO_ADD'),
            duration: 3000,
          });
        } else if (validBooks.length === 1) {
          setSnackbarMessage({
            text: t('BOOK_ADDED_SUCCESSFULLY', { bookTitle: getBook(validBooks[0])?.title || t('BOOK') }),
            duration: 3000,
          });
        } else {
          const firstBook = getBook(validBooks[0])?.title || 'Unknown';
          const otherBooksCount = validBooks.length - 1;
          setSnackbarMessage({
            text: t('SNACKBAR_ADDED_BOOKS', { firstBook: firstBook, otherBooksCount: otherBooksCount }),
            duration: 3000,
          });
        }

        refreshBookList();
      } catch (error) {
        console.error('Error adding books:', error);
        setSnackbarMessage({
          text: t('FAILED_TO_ADD_BOOKS_GENERIC_ERROR'),
          duration: null,
        });
      }
    };

    handleMultipleFileUpload();
  };

  const handleToggleArchive = async (id: string) => {
    archiveBook(id);
    // setBooks(getBooks());
    refreshBookList();

    console.log('toggled archive on book with id: ', id, books);
  };

  const handleDeleteBook = async (id: string) => {
    console.log('handle delete book');
    const books = await deleteBook(id);
    setBooks(books);
    refreshBookList();
  };

  const handleResetBook = async (id: string) => {
    console.log('handle reset book');
    const book = await resetBook(id);
    if (book) {
      setBooks({ ...books, [book.id]: book });
      setSnackbarMessage({ text: t('BOOK_RESET_SUCCESSFUL'), duration: 5000 });
      refreshBookList();
    } else {
      setSnackbarMessage({ text: t('BOOK_RESET_FAILED'), duration: 5000 });
    }
  };

  const handleReplaceFileClick = (id: string) => {
    // Assuming replaceBookFile is an API to replace the book file
    console.debug('handleReplaceFileClick', id);
    setReplaceFileId(id);
    fileInputRef.current?.click();
  };

  const handleReplaceFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.debug('handleReplaceFileChange', event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files?.[0];
      if (replaceFileId && file) {
        console.log('File selected, id:', file.name, replaceFileId);
        await replaceBookFile(replaceFileId, file);
        setReplaceFileId(null);
        setSnackbarMessage({ text: t('BOOK_FILE_REPLACED_SUCCESSFULLY'), duration: 5000 });
        refreshBookList();
      }
    }
  };

  const handleAddTranslatedVersion = (id: string) => {
    console.debug('handleAddTranslatedVersion', id);
    translatedVersionId = id;
    translatedFileInputRef.current?.click();
  };

  const handleAddTranslatedVersionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.debug('handleAddTranslatedVersionChange', event.target.files);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files?.[0];
      console.debug('handleAddTranslatedVersionChange', file);
      if (translatedVersionId && file) {
        console.log('File selected, id:', file.name, translatedVersionId);
        await addTranslatedVersion(translatedVersionId, file);
        translatedVersionId = '';
        setSnackbarMessage({ text: t('TRANSLATED_VERSION_ADDED'), duration: 5000 });
        refreshBookList();
      } else if (!file) {
        setSnackbarMessage({ text: t('NO_FILE_SELECTED'), duration: 3000 });
      } else {
        setSnackbarMessage({ text: t('UNKNOWN_ERROR_ADDING_TRANSLATED_VERSION'), duration: 5000 });
      }
    }
  };

  const openBook = (id: string) => {
    navigate(`/read/${id}`);
  };

  const handleCustomLogout = () => {
    setUser(null);
    logoutUser();
  };

  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    'https://read.by.tc/auth/callback'
  )}&scope=${encodeURIComponent(
    'email profile openid'
  )}&response_type=code&access_type=offline&prompt=consent&state=${encodeURIComponent(
    import.meta.env.DEV ? 'dev' : 'prod'
  )}`;

  /**
   * Renders the shelf dropdown with localized labels.
   */
  const shelfDropdown = () => {
    return (
      <FormControl sx={{ minWidth: 140, width: '42%' }}>
        <InputLabel
          id='book-filter-label'
          sx={{
            fontSize: '1em',
            color: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {t('SHELF_LABEL')}
        </InputLabel>
        <Select
          labelId='book-filter-label'
          value={bookFilter}
          label={t('SHELF_LABEL')}
          onChange={(e) => setBookFilter(e.target.value as string)}
          sx={{
            color: getTheme() === 'dark' ? '#fff' : '#000',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: getTheme() === 'dark' ? '#90caf9' : '#1976d2',
            },
            '& .MuiSvgIcon-root': {
              color: getTheme() === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
            },
            '& .MuiInputBase-input': {
              padding: '6px 1em',
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: getTheme() === 'dark' ? '#424242' : '#fff',
                '& .MuiMenuItem-root': {
                  color: getTheme() === 'dark' ? '#fff' : '#000',
                },
              },
            },
          }}
        >
          <MenuItem value='active'>{t('ACTIVE_SHELF')}</MenuItem>
          <MenuItem value='archived'>{t('ARCHIVED_SHELF')}</MenuItem>
          <MenuItem value='all'>{t('ALL_SHELF')}</MenuItem>
        </Select>
      </FormControl>
    );
  };

  // ** Handler to save selected language to both Context and prefs. **
  const handleLanguageSave = (selectedLanguage: string) => {
    console.log('handleLanguageSave', selectedLanguage);
    setLanguage(selectedLanguage);
    changePrefs({ languageCode: selectedLanguage });
    setLanguageModalOpen(false);
  };

  /**
   * Handles the refresh action by clearing translations and reloading the page.
   */
  const handleRefresh = async () => {
    window.location.reload(); // Reload the page
  };

  // Don't render anything until translations are ready
  if (isLoading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  // In the return below, we wrap the outer content in ShepherdTour so that the child
  // components (and in particular the TourStarter) have access to the tour context.
  return (
    localForageLoaded && (
      <Container
        sx={{
          backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
          paddingTop: '8px',
        }}
      >
        {/* Render the TourStarter as a descendant of ShepherdTour */}
        <TourStarter user={user} />

        {import.meta.env.DEV && (
          <Typography
            variant='caption'
            color='#888'
            sx={{
              fontSize: '0.5rem',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {t('DEV_MODE')}
          </Typography>
        )}
        <input ref={fileInputRef} type='file' hidden onChange={(e) => handleReplaceFileChange(e)} />
        <input ref={translatedFileInputRef} type='file' hidden onChange={(e) => handleAddTranslatedVersionChange(e)} />
        <Stack
          direction='row'
          spacing={1}
          margin='0 0 12px 0px'
          alignItems='center'
          sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}
          whiteSpace='nowrap'
        >
          <AddBookButton
            variant='contained'
            component='label'
            sx={{
              width: '20%',
              textTransform: 'none',
              backgroundColor: '#5c6bc0',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('ADD_BOOK_BUTTON')}
            <input
              type='file'
              hidden
              multiple // TODO: should be single for replace and translated version
              accept='.epub,application/epub+zip'
              onChange={handleFileChange}
            />
          </AddBookButton>
          {user ? (
            <LogoutButton
              variant='outlined'
              component='label'
              onClick={handleCustomLogout}
              sx={{
                width: '20%',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#3949ab',
                },
              }}
            >
              {t('LOGOUT_BUTTON')}
            </LogoutButton>
          ) : (
            // IMPORTANT: note the added className "login-button" for the tour to attach.
            <LoginButton
              className='login-button'
              variant='contained'
              component='label'
              onClick={() => (window.location.href = authUrl)}
              sx={{
                width: '20%',
                textTransform: 'none',
                backgroundColor: '#5c6bc0',
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: '#3949ab',
                },
              }}
            >
              {t('LOGIN_BUTTON')}
            </LoginButton>
          )}
          <Box flexGrow={1} />
          <IconButton
            onClick={handleRefresh} // Use the new handler
            sx={{
              padding: '0px 0',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {isOnline || !user ? <RefreshIcon color='primary' /> : <CloudOffIcon style={{ color: '#b22' }} />}
          </IconButton>
          <Box flexGrow={1} />
          <BackupButton
            variant='outlined'
            component='label'
            onClick={() => {
              setSnackbarMessage({
                text: t('BACKUP_IN_PROGRESS'),
                duration: null,
              });
              return createBackupZip();
            }}
            sx={{
              width: '20%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('BACKUP_BUTTON')}
          </BackupButton>
          <RestoreButton
            variant='outlined'
            component='label'
            sx={{
              width: '20%',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#3949ab',
              },
            }}
          >
            {t('RESTORE_BUTTON')}
            <input id='backupInput' type='file' hidden accept='application/zip' onChange={handleLoadBackup} />
          </RestoreButton>
        </Stack>
        <Stack
          direction='row'
          sx={{
            m: '0 0px 32px 0',
            alignItems: 'center',
          }}
          spacing={1}
        >
          {shelfDropdown()}
          <Box flexGrow={1} />

          {/* Language selection button – sits just left of the "version" button */}
          <LanguageButton
            variant='outlined'
            onClick={() => {
              setSelectedLanguage(currentLanguage); // reset to currentLanguage
              setLanguageModalOpen(true);
            }}
            sx={{
              textTransform: 'none',
              width: '20%',
              // padding: '4px 8px'
            }}
          >
            {currentLanguage.toUpperCase()}
          </LanguageButton>

          <VersionButton
            variant='outlined'
            color='secondary'
            onClick={() => setChangelogOpen(true)}
            sx={{
              textTransform: 'none',
              width: '20%',
              //   padding: '4px 8px'
            }}
          >
            v{version}
          </VersionButton>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
          {user && (
            <>
              <Avatar src={user.picture} />
              <Typography
                sx={{
                  color: getTheme() === 'dark' ? 'white' : 'black',
                  fontWeight: 'bold',
                  fontSize: '150%',
                }}
              >
                {user.email && streaks?.[user.email]?.days
                  ? t('STREAK_TIME_TEXT', {
                      numDays: streaks[user.email].days,
                      streakEmoji: getStreakEmoji(streaks[user.email].today),
                    })
                  : user.given_name
                  ? t('HI_NAME', { givenName: user.given_name })
                  : t('HI_YOU')}
              </Typography>
            </>
          )}
        </Stack>
        {isOnline &&
          (streakTime.length > 0 ? (
            <Container
              sx={{
                backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
                paddingTop: '8px',
              }}
            >
              <div className='embla'>
                <div className='embla__viewport' ref={emblaRef}>
                  <div className='embla__container'>
                    {charts.map((chart) => (
                      <div className='embla__slide' key={chart.id}>
                        {chart.component}
                      </div>
                    ))}
                  </div>
                </div>
                <div className='embla__dots'>
                  {charts.map((_, index) => (
                    <DotButton
                      key={index}
                      selected={index === selectedIndex}
                      onClick={() => emblaApi && emblaApi.scrollTo(index)}
                    />
                  ))}
                </div>
                <PrevButton onClick={() => emblaApi && emblaApi.scrollPrev()} disabled={!canScrollPrev} />
                <NextButton onClick={() => emblaApi && emblaApi.scrollNext()} disabled={!canScrollNext} />
              </div>
            </Container>
          ) : (
            // empty space while waiting for data
            <Box sx={{ height: '200px', width: '100%' }} />
          ))}

        <Stack
          justifyContent='center'
          spacing={1}
          paddingBottom={2}
          paddingTop={2}
          flexWrap='wrap'
          useFlexGap
          direction='row'
          overflow='auto'
          sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}
        >
          {filteredBooks.map(([id, book]) => (
            <Box
              key={book.id}
              onClick={() => openBook(book.id)}
              sx={{
                display: 'flex',
                backgroundColor: getTheme() === 'dark' ? 'black' : 'white',
              }}
            >
              <BookCover
                book={book}
                onToggleArchive={() => handleToggleArchive(book.id)}
                onDeleteBook={() => handleDeleteBook(book.id)}
                isArchived={book.location === 'archived'}
                userProgress={book.hash ? progressInfo?.[book.hash] : undefined}
                fileInputRef={fileInputRef}
                onReplaceFile={() => handleReplaceFileClick(book.id)}
                onAddTranslatedVersion={() => handleAddTranslatedVersion(book.id)}
                onResetBook={() => handleResetBook(book.id)}
              />
            </Box>
          ))}
        </Stack>

        {changelogOpen && <Changelog onClose={() => setChangelogOpen(false)} />}

        {/* Language Picker Modal */}
        <Dialog
          open={languageModalOpen}
          onClose={() => setLanguageModalOpen(false)}
          aria-labelledby='language-dialog-title'
        >
          <DialogTitle id='language-dialog-title'>{t('SELECT_LANGUAGE')}</DialogTitle>
          <DialogContent>
            <FormControl sx={{ minWidth: 200, marginTop: 2 }}>
              <InputLabel>{t('LANGUAGE')}</InputLabel>
              <Select
                label={t('LANGUAGE')}
                value={selectedLanguage}
                onChange={(e) => {
                  setSelectedLanguage(e.target.value);
                  handleLanguageSave(e.target.value);
                }}
              >
                {LANGUAGES.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
        </Dialog>
      </Container>
    )
  );
};

export default BookList;
