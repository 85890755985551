import { useState } from 'react';
import { Link, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import Markdown from 'react-markdown';
import { getTheme } from '../utils/books';
import changelog from '../../CHANGELOG.md?raw';
import autochangelog from '../../AUTO_CHANGELOG.md?raw';
import { useTranslation } from '../utils/i18n';

interface ChangelogProps {
  onClose: () => void;
}

/**
 * Displays the changelog or version history in a dialog.
 *
 * @param {ChangelogProps} props - The properties for the Changelog component.
 * @returns A Dialog component showing the changelog or version history.
 */
export function Changelog({ onClose }: ChangelogProps) {
  const t = useTranslation();
  const [isAutoChangelog, setIsAutoChangelog] = useState(false);

  return (
    <Dialog open={true} onClose={onClose} sx={{ backgroundColor: getTheme() === 'dark' ? 'black' : 'white' }}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {isAutoChangelog ? t('VERSION_HISTORY') : t('RELEASE_NOTES')}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: getTheme() === 'dark' ? 'white' : 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: '0.9em',
          '& a': {
            color: getTheme() === 'dark' ? '#6495ED' : '#4169E1',
            textDecoration: 'none',
          },
        }}
      >
        <Link component='button' onClick={() => setIsAutoChangelog(!isAutoChangelog)}>
          {isAutoChangelog ? t('SHOW_RELEASE_NOTES') : t('SHOW_VERSION_HISTORY')}
        </Link>
        <Markdown>{isAutoChangelog ? autochangelog : changelog}</Markdown>
      </DialogContent>
    </Dialog>
  );
}
