import React from 'react';
import { Button, Box, Typography, Container } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import GoogleButton from 'react-google-button';

const LoginPage: React.FC = () => {
  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
    import.meta.env.VITE_GOOGLE_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(
    window.location.origin + '/auth/callback' // Ensure this matches your backend redirect URI
  )}&scope=${encodeURIComponent(
    'email profile openid'
  )}&response_type=code&access_type=offline&prompt=consent&state=${encodeURIComponent(
    import.meta.env.DEV ? 'dev' : 'prod'
  )}`;

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component='h1' variant='h5' sx={{ my: 2 }}>
          Welcome to weReader
        </Typography>
        <GoogleButton
          onClick={() => {
            window.location.href = authUrl;
          }}
        />
        {/* <Button
          variant='contained'
          startIcon={<GoogleIcon />}
          onClick={() => {
            window.location.href = authUrl;
          }}
          sx={{ mt: 2 }}
        >
          Sign in with Google
        </Button> */}
      </Box>
    </Container>
  );
};

export default LoginPage;
