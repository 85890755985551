import { version } from '../../package.json';
import { useOnlineStore } from '../store/onlineStore';

/**
 * Checks the current version against the server version.
 * @returns {Promise<string | undefined>} The current version of the server, or undefined on error.
 * @async
 */
const checkVersion = async (): Promise<string | undefined> => {
  try {
    const response = await fetch('/api/check_version', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ version: version, dev: import.meta.env.DEV }),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    useOnlineStore.setState({ isOnline: true });

    const data = await response.json();
    return data?.version;
  } catch (error) {
    useOnlineStore.setState({ isOnline: false });
    console.error('Error checking version:', error);
    return;
  }
};

/**
 * Fetches translations from the server for the specified language.
 * @param lang - The language code.
 * @returns A promise that resolves to the translations object.
 */
const getTranslationsFromServer = async (lang: string): Promise<Record<string, string>> => {
  const response = await fetch('/api/translate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ lang: lang.toLowerCase().slice(0, 2) }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch translations');
  }

  const data = await response.json();
  console.log('data', data);
  return data;
};

export { checkVersion, getTranslationsFromServer };
