import { useState, useCallback, useEffect, useRef } from 'react';
import { IconButton, Tooltip, Stack, Divider, Dialog, Paper } from '@mui/material';
import {
  AutoStories as AutoStoriesIcon,
  AutoFixOff as AutoFixOffIcon,
  AutoFixHigh as AutoFixHighIcon,
  AutoFixNormal as AutoFixNormalIcon,
  AutoAwesome as AutoAwesomeIcon,
  //   RecentActors as RecentActorsIcon,
  Circle as CircleIcon,
  Translate as TranslateIcon,
  Title as TitleIcon,
  //   Grass as GrassIcon,
} from '@mui/icons-material';
import { DictionaryView } from './DictionaryView';
import Geepers from './Geepers';
import { WiktionaryView } from './WiktionaryView';
import { RevoDictionaryView } from './RevoDictionaryView';
import type { Book } from 'epubjs';
import type { BookMeta, NoteCommentGroup } from '../types/book';
import { useOnlineStore } from '../store/onlineStore';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useTranslation } from '../utils/i18n';

interface ContextMenuProps {
  x: number;
  y: number;
  open: boolean;
  onClose: (e: MouseEvent) => void;
  onSelectColor: (color: string, text: string, cfiRange: string) => void;
  text: string;
  context: string;
  cfiRange: string;
  book: Book;
  progress: number;
  lastColor?: string;
  meta: BookMeta;
  addNote?: (cfiRange: string, color: string, text: string, comments?: NoteCommentGroup) => void;
  getContextFunction: (selection: Selection | null) => string;
  getTranslationFunction: (start: number, end: number, lang: string) => string;
  selection: Selection | null;
  selectStartParagraphIndex: number;
  selectEndParagraphIndex: number;
  languageCode?: string;
}

export default function ContextMenu({
  x,
  y,
  onSelectColor,
  open,
  onClose,
  text,
  context,
  cfiRange,
  book,
  progress,
  lastColor,
  meta,
  addNote,
  getContextFunction,
  getTranslationFunction,
  selection,
  selectStartParagraphIndex,
  selectEndParagraphIndex,
  languageCode,
}: ContextMenuProps) {
  const t = useTranslation();
  const [dictionaryOpen, setDictionaryOpen] = useState(false);
  const [geepersOpen, setGeepersOpen] = useState(false);
  const [geepersCommand, setGeepersCommand] = useState('magic');
  const buttonSize = 40;
  const buttonMargin = 0.5;
  const [wiktionaryOpen, setWiktionaryOpen] = useState(false);
  const [revoDictionaryOpen, setRevoDictionaryOpen] = useState(false);
  const [bookLanguage, setBookLanguage] = useState(meta.languageCode || 'en');

  // New state and ref for handling long press on the magic button
  const [magicLongPress, setMagicLongPress] = useState<boolean>(false);
  const longPressTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const longPressDuration = 300; // milliseconds

  // Reset magicLongPress when the context menu closes.  VERY IMPORTANT.
  useEffect(() => {
    if (!open) {
      setMagicLongPress(false);
    }
  }, [open]);

  /**
   * Handles mouse/touch down events for the magic button.
   * Starts a timer to detect a long press.
   */
  const handleMagicMouseDown = () => {
    longPressTimeoutRef.current = setTimeout(() => {
      setMagicLongPress(true);
    }, longPressDuration);
  };

  /**
   * Handles mouse/touch up/leave events for the magic button.
   * Cancels the long press timer and, if a long press was not activated,
   * triggers the default "magic" command.
   */
  const handleMagicMouseUp = () => {
    if (longPressTimeoutRef.current) {
      clearTimeout(longPressTimeoutRef.current);
      longPressTimeoutRef.current = null;
      if (!magicLongPress) {
        // Short press: trigger default magic behavior
        handleGeepersOpen(Math.random() < 0.99 ? 'magic' : 'haiku');
      }
    }
  };

  const handleColorSelect = useCallback(
    (e: MouseEvent) => {
      onSelectColor(lastColor || '#ffff00', text, cfiRange);
      onClose(e as MouseEvent);
    },
    [text, cfiRange, lastColor]
  );

  const handleDictionaryOpen = () => {
    console.log('handleDictionaryOpen', bookLanguage, text);
    if (text) {
      if (bookLanguage.toLowerCase().startsWith('en')) {
        setDictionaryOpen(true);
      } else {
        console.debug('setWiktionaryOpen');
        setWiktionaryOpen(true);
      }
    }
  };

  const handleRevoDictionaryOpen = () => {
    console.log('handleRevoDictionaryOpen', bookLanguage, text);
    if (text) {
      console.debug('setRevoDictionaryOpen');
      setRevoDictionaryOpen(true);
    }
  };

  const handleDictionaryClose = () => {
    setDictionaryOpen(false);
  };

  const handleWiktionaryClose = () => {
    setWiktionaryOpen(false);
  };

  const handleRevoDictionaryClose = () => {
    setRevoDictionaryOpen(false);
  };

  const handleGeepersOpen = (command = 'magic') => {
    setGeepersCommand(command);
    setGeepersOpen(true);
  };

  const handleGeepersClose = () => {
    setGeepersOpen(false);
  };

  useEffect(() => {
    if (meta.languageCode) {
      setBookLanguage(
        meta.languageCode.slice(0, 2).toLowerCase() || navigator.language.slice(0, 2).toLowerCase() || 'en'
      );
    }
  }, [meta.languageCode]);

  const isOnline = useOnlineStore((state) => state.isOnline);

  return (
    <>
      {/* <Menu
        id="context-menu"
        disableAutoFocus={true}
        disableEnforceFocus={false}
        autoFocus={false}
        open={open}
        variant={"menu"}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: y, left: x }}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
          disablePadding: true,
        }} */}
      <Dialog
        hideBackdrop
        id='context-menu'
        open={open}
        onClose={onClose}
        disableScrollLock={true}
        disableEscapeKeyDown={true}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          style: {
            position: 'absolute',
            top: y,
            // left: x,
            width: 'auto',
            height: 'auto',
            pointerEvents: 'auto',
            overflow: 'visible',
            visibility: open ? 'visible' : 'hidden',
          },
        }}
        style={{
          pointerEvents: open ? 'auto' : 'none',
          transition: 'opacity 0.2s ease-in-out',
          opacity: open ? 1 : 0,
          visibility: open ? 'visible' : 'hidden',
        }}
      >
        <Stack direction='row' spacing={0} divider={<Divider orientation='vertical' flexItem sx={{ mx: 0 }} />}>
          <Tooltip title={t('CONTEXT_MENU_HIGHLIGHT_OR_ADD_NOTE')}>
            <IconButton onClick={(e: any) => handleColorSelect(e)} aria-label='highlight'>
              <CircleIcon
                style={{
                  margin: buttonMargin,
                  color: lastColor || '#ffff00',
                  fontSize: buttonSize,
                }}
              />
            </IconButton>
          </Tooltip>
          {isOnline && (
            <Tooltip title={t('CONTEXT_MENU_LOOKUP_DICTIONARY')}>
              <IconButton onClick={handleDictionaryOpen} aria-label='dictionary'>
                <AutoStoriesIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
          {isOnline && bookLanguage.toLowerCase().startsWith('eo') && (
            <Tooltip title={t('CONTEXT_MENU_LOOKUP_REVO')}>
              <IconButton onClick={handleRevoDictionaryOpen} aria-label='revo'>
                <TitleIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
          {meta.languageCode?.toLowerCase().slice(0, 2) !== languageCode && isOnline && (
            <Tooltip title={t('CONTEXT_MENU_AI_TRANSLATE')}>
              <IconButton onClick={() => handleGeepersOpen('translate')} aria-label='translate'>
                <TranslateIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
          {isOnline && (
            // <Tooltip title={t('CONTEXT_MENU_AI_MAGIC')}>
            //   {/*
            //     Option 2:
            //     Wrap the folded-out magic menu with MUI's Paper so it inherits the context menu style naturally.
            //   */}
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <IconButton
                aria-label='magic'
                onMouseDown={handleMagicMouseDown}
                onMouseUp={handleMagicMouseUp}
                onTouchStart={handleMagicMouseDown}
                onTouchEnd={handleMagicMouseUp}
                onMouseLeave={handleMagicMouseUp}
              >
                <AutoAwesomeIcon sx={{ fontSize: buttonSize, m: buttonMargin }} />
              </IconButton>
              {magicLongPress && (
                <Paper
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    mt: 0,
                    zIndex: 10,
                    visibility: open ? 'visible' : 'hidden',
                  }}
                >
                  <Stack direction='column' spacing={1} sx={{ p: 1 }}>
                    <Tooltip title={t('CONTEXT_MENU_AI_MAGIC_BRIEF')}>
                      <IconButton
                        size='small'
                        onClick={() => {
                          handleGeepersOpen('magic_brief');
                          setMagicLongPress(false);
                        }}
                        aria-label='magic_brief'
                      >
                        <AutoFixOffIcon sx={{ fontSize: buttonSize * 0.8, m: buttonMargin }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('CONTEXT_MENU_AI_MAGIC_DETAILED')}>
                      <IconButton
                        size='small'
                        onClick={() => {
                          handleGeepersOpen('magic_detailed');
                          setMagicLongPress(false);
                        }}
                        aria-label='magic_detailed'
                      >
                        <AutoFixNormalIcon sx={{ fontSize: buttonSize * 0.8, m: buttonMargin }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('CONTEXT_MENU_AI_MAGIC_COMPREHENSIVE')}>
                      <IconButton
                        size='small'
                        onClick={() => {
                          handleGeepersOpen('magic_comprehensive');
                          setMagicLongPress(false);
                        }}
                        aria-label='magic_comprehensive'
                      >
                        <AutoFixHighIcon sx={{ fontSize: buttonSize * 0.8, m: buttonMargin }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Paper>
              )}
            </div>
          )}
          {isOnline && (bookLanguage.startsWith('fr') || bookLanguage.startsWith('eo')) && (
            <Tooltip title={t('CONTEXT_MENU_AI_EXPLAIN_DIFFERENCE')}>
              <IconButton
                onClick={() =>
                  handleGeepersOpen(
                    bookLanguage.startsWith('fr') ? 'french' : bookLanguage.startsWith('eo') ? 'esperanto' : 'magic'
                  )
                }
                aria-label='difference'
              >
                <CompareArrowsIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}{' '}
          {/* {isOnline && (
            <Tooltip title='Have AI compose a haiku'>
              <IconButton onClick={() => handleGeepersOpen('haiku')} aria-label='haiku'>
                <GrassIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )} */}
          {/* {isOnline && (
            <Tooltip title='Have AI summarize or explain a character or other plot element'>
              <IconButton onClick={() => handleGeepersOpen('deepdive')} aria-label='deepdive'>
                <RecentActorsIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )} */}
        </Stack>
      </Dialog>
      <DictionaryView open={dictionaryOpen} onClose={handleDictionaryClose} word={text} />
      <WiktionaryView open={wiktionaryOpen} onClose={handleWiktionaryClose} word={text} languageCode={bookLanguage} />
      <RevoDictionaryView
        open={revoDictionaryOpen}
        onClose={handleRevoDictionaryClose}
        word={text}
        // lang={targetLanguage}
      />
      <Geepers
        open={geepersOpen}
        onClose={handleGeepersClose}
        text={text}
        context={context}
        command={geepersCommand}
        book={book}
        progress={progress}
        meta={meta}
        cfiRange={cfiRange}
        addNote={addNote}
        getContextFunction={getContextFunction}
        getTranslationFunction={getTranslationFunction}
        selection={selection}
        selectStartParagraphIndex={selectStartParagraphIndex}
        selectEndParagraphIndex={selectEndParagraphIndex}
        languageCode={languageCode || navigator.language.slice(0, 2).toLowerCase() || 'en'}
      />
    </>
  );
}
