import React from 'react';
import { Dialog, DialogContent, DialogTitle, List, ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from '../utils/i18n';

interface BookCoverContextMenuProps {
  open: boolean;
  onClose: () => void;
  onToggleArchive: () => void;
  onReplaceFile: () => void;
  onDeleteBook: () => void;
  isArchived: boolean;
  onAddTranslatedVersion: () => void;
  onResetBook: () => void;
}

/**
 * BookCoverContextMenu Component
 *
 * Displays a modal dialog with various book-related actions,
 * with all labels provided via translations.
 *
 * @param {BookCoverContextMenuProps} props - Component props
 * @returns {JSX.Element} The rendered component
 */
const BookCoverContextMenu: React.FC<BookCoverContextMenuProps> = ({
  open,
  onClose,
  onToggleArchive,
  onReplaceFile,
  onDeleteBook,
  isArchived,
  onAddTranslatedVersion,
  onResetBook,
}) => {
  const t = useTranslation();

  /**
   * Handles the dialog's background click to prevent event propagation.
   *
   * @param {React.MouseEvent} event - The mouse event
   */
  const handleBackdropClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} onClick={handleBackdropClick} sx={{ background: 'rgba(0, 0, 0, 0.5)' }}>
      <DialogContent>
        <List>
          <ListItemButton
            onClick={(event) => {
              event.stopPropagation();
              onToggleArchive();
              onClose();
            }}
          >
            <ListItemText
              primary={isArchived ? t('BOOK_COVER_CONTEXTMENU_UNARCHIVE') : t('BOOK_COVER_CONTEXTMENU_ARCHIVE')}
            />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => {
              event.stopPropagation();
              onReplaceFile();
              onClose();
            }}
          >
            <ListItemText primary={t('BOOK_COVER_CONTEXTMENU_REPLACE_EPUB')} />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => {
              event.stopPropagation();
              onAddTranslatedVersion();
              onClose();
            }}
          >
            <ListItemText primary={t('BOOK_COVER_CONTEXTMENU_ADD_TRANSLATED')} />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => {
              event.stopPropagation();
              onDeleteBook();
              onClose();
            }}
          >
            <ListItemText primary={t('BOOK_COVER_CONTEXTMENU_DELETE_BOOK')} />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => {
              event.stopPropagation();
              onResetBook();
              onClose();
            }}
          >
            <ListItemText primary={t('BOOK_COVER_CONTEXTMENU_RESET_BOOK')} />
          </ListItemButton>
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default BookCoverContextMenu;
