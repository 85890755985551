import type { BookSettings, StatusBarBoxInfo, Prefs, BookMeta } from '../types/book';

export const defaultBookSettings: BookSettings = {
  fontSize: 11,
  lineSpacing: 0.95,
  fontFamily: 'default',
  fontWeight: 0,
  margin: -1,
  marginY: -0.1,
  indent: -0.25,
  alignment: 'justify',
  flow: 'scrolled',
  showNotes: false,
  lastColor: '#ffff00',
  updates: [],
  progress: 0,
  allImportant: false,
  noteVisibility: 'all',
};

export const defaultFavorite: Partial<BookSettings> = {
  fontSize: 11,
  lineSpacing: 0.95,
  fontFamily: 'default',
  fontWeight: 0,
  margin: 4,
  alignment: 'justify',
  flow: 'scrolled',
  marginY: -0.1,
  indent: -0.25,
  lastColor: '#ffff00',
  allImportant: false,
  noteVisibility: 'all',
};

export const defaultStatusBarBoxes: StatusBarBoxInfo[] = [
  { position: -1, info: 'chapter_name' },
  { position: -2, info: 'none' },
  { position: 1, info: 'book_progress' },
  { position: 2, info: 'none' },
];

export const defaultPrefs: Prefs = {
  theme: 'dark',
  fullscreen: false,
  statusBarBoxes: defaultStatusBarBoxes,
  favorite: defaultFavorite,
};

export const defaultBookMeta: BookMeta = {
  id: '',
  title: 'Untitled',
  author: 'Anonymous',
  timestamp: 0,
  notes: {},
  visibleAt: {},
  settings: defaultBookSettings,
  location: 'active',
};

export const defaultFontSize = 16;
