import type { Person } from './types/book';
import languages from './data/languages.json';

export const aiUser: Person = {
  email: 'ai',
  name: 'Skynet',
  picture: '/ai_avatar_1.webp',
  color: '#00ff00',
};

export const highlightColors = ['#ff0', '#0f0', '#f22', '#39f', '#2489b3'];
export const currentStoreVersion = 25;
export const hoursForRecentWPM = 12;
export const locationSize = 256;
export const charsPerWord = 5.5;

export const dbConfig = {
  dbName: 'ePubLibrary',
  fileStoreName: 'ePubFiles',
  objectStoreName: 'ePubObjects',
  metadataStoreName: 'ePubFileMetadata',
  dbVersion: 4,
};

export const localStorageBooksName = 'books';
export const localStorageNotesStatusName = 'notesStatus';
export const localStorageCurrentUserName = 'currentUser';
export const localStorageFollowingName = 'following';
export const localStorageMiscName = 'misc';

export const eventsUrl = '/api/events';

interface Language {
  code: string;
  name: string;
}

export const LANGUAGES: Language[] = languages;
