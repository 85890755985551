import React, { useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  DarkMode as DarkModeIcon,
  ExpandMore as ExpandMoreIcon,
  FormatAlignJustify as FormatAlignJustifyIcon,
  FormatAlignLeft as FormatAlignLeftIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  LightMode as LightModeIcon,
  SwapHoriz as SwapHorizIcon,
  SwapVert as SwapVertIcon,
  Brightness6 as Brightness6Icon,
} from '@mui/icons-material';
import { getFontList } from '../fonts.config';
import type { BookSettings } from '../types/book';
import { isIOS, isMobileOnly } from 'react-device-detect';
import { AppContext } from '../context/AppContext';
import { TranslationsContext, useTranslation } from '../utils/i18n';
import { LANGUAGES } from '../config';
import withTooltip from './withTooltip';

interface SettingsComponentProps {
  settings: BookSettings;
  updateSettings: (settings: BookSettings, progress?: number) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
}

/**
 * SettingsComponent - display and update UI settings.
 */
const SettingsComponent: React.FC<SettingsComponentProps> = ({ updateSettings, settings, onDragStart, onDragEnd }) => {
  const progress = settings.progress;
  const googleFonts = getFontList();
  const { prefs, changePrefs } = useContext(AppContext);
  const { setLanguage } = useContext(TranslationsContext);
  const t = useTranslation();

  const handleFontSizeChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        fontSize: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleLineSpacingChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        lineSpacing: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleMarginChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        margin: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newValue: string | string[]) => {
    updateSettings({
      alignment: Array.isArray(newValue) ? newValue[0] : newValue,
    });
  };

  const handleFontWeightChange = (event: Event, newValue: number | number[]) => {
    updateSettings(
      {
        fontWeight: Array.isArray(newValue) ? newValue[0] : newValue,
      },
      progress
    );
  };

  const handleFlowChange = () => {
    updateSettings({ flow: settings.flow === 'scrolled' ? 'paginated' : 'scrolled' }, progress);
    // Transition to 0 opacity over 500ms
    document.body.style.transition = 'opacity 500ms ease-out';
    document.body.style.opacity = '0';
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleFullscreenChange = () => {
    const newFullscreen = !prefs?.fullscreen;
    changePrefs({ fullscreen: newFullscreen });
  };

  const handleNoteVisibilityChange = (newNoteVisibility: BookSettings['noteVisibility']) => {
    updateSettings({ noteVisibility: newNoteVisibility }, progress);
  };

  const handleThemeChange = () => {
    const newTheme = prefs?.theme === 'light' ? 'dark' : prefs?.theme === 'dark' ? 'auto' : 'light';
    changePrefs({ theme: newTheme });
  };

  const handleLanguageChange = (newLanguageCode: string) => {
    setLanguage(newLanguageCode);
    changePrefs({ languageCode: newLanguageCode });
  };

  const handleSaveSettings = () => {
    const { updates, showNotes, status, progress, location, ...favoriteSettings } = settings;
    console.debug('handleSaveFavorite', favoriteSettings);
    changePrefs({ favorite: favoriteSettings });
  };

  const handleLoadSettings = () => {
    if (prefs?.favorite) {
      updateSettings({ ...prefs.favorite });
    }
  };

  const sliderSettings = [
    {
      label: t('MARGIN'),
      value: settings.margin,
      onChange: handleMarginChange,
      min: -1,
      max: 20,
      step: 1,
      defaultValue: -1,
    },
    {
      label: t('LINE_SPACING'),
      value: settings.lineSpacing,
      onChange: handleLineSpacingChange,
      min: 0.95,
      max: 2.0,
      step: 0.05,
      defaultValue: 0.95,
    },
    {
      label: t('PARAGRAPH_SPACING'),
      value: settings.marginY === undefined ? -0.1 : settings.marginY,
      onChange: (_: Event, newValue: number | number[]) => updateSettings({ marginY: newValue as number }),
      min: -0.1,
      max: 1,
      step: 0.1,
      defaultValue: -0.1,
    },
    {
      label: t('PARAGRAPH_INDENT'),
      value: settings.indent === undefined ? -0.25 : settings.indent,
      onChange: (_: Event, newValue: number | number[]) => updateSettings({ indent: newValue as number }),
      min: -0.25,
      max: 3,
      step: 0.25,
      defaultValue: -0.25,
    },
    {
      label: t('FONT_WEIGHT'),
      value: settings.fontWeight,
      onChange: handleFontWeightChange,
      min: 0,
      max: 800,
      step: 100,
      defaultValue: 0,
    },
  ];

  // Create wrapped versions of common controls with tooltips.
  // Note: The translation keys used here (e.g. 'FONT_FAMILY_TOOLTIP') must be defined in your translations.
  const TooltipFontFamilySelect = withTooltip(Select, 'FONT_FAMILY_TOOLTIP');
  const TooltipLanguageSelect = withTooltip(Select, 'LANGUAGE_TOOLTIP');

  // Wrapped ToggleButtons for icon-only controls:
  const AlignLeftButton = withTooltip(ToggleButton, 'LEFT_ALIGNED_TOOLTIP');
  const JustifyButton = withTooltip(ToggleButton, 'JUSTIFIED_TOOLTIP');
  const FlowToggleButton = withTooltip(ToggleButton, 'FLOW_TOGGLE_TOOLTIP');
  const FullscreenToggleButton = withTooltip(ToggleButton, 'FULLSCREEN_TOGGLE_TOOLTIP');
  const ThemeToggleButton = withTooltip(ToggleButton, 'THEME_TOGGLE_TOOLTIP');

  // Wrapped Buttons for save / load:
  const LoadSettingsButton = withTooltip(Button, 'LOAD_SETTINGS_TOOLTIP');
  const SaveSettingsButton = withTooltip(Button, 'SAVE_SETTINGS_TOOLTIP');

  const alignmentButtons = () => {
    return (
      <ToggleButtonGroup
        value={settings.alignment}
        exclusive
        onChange={handleAlignmentChange}
        aria-label={t('TEXT_ALIGNMENT')}
      >
        <AlignLeftButton value='left' aria-label={t('LEFT_ALIGNED')}>
          <FormatAlignLeftIcon />
        </AlignLeftButton>
        <JustifyButton value='justify' aria-label={t('JUSTIFIED')}>
          <FormatAlignJustifyIcon />
        </JustifyButton>
      </ToggleButtonGroup>
    );
  };

  const isValidLanguageCode = (code: string) => {
    return /^[a-zA-Z]{2,3}(-[a-zA-Z]{2,3})?(-[a-zA-Z]{2,4})?$/.test(code);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant='body1' sx={{ marginTop: '0em', textAlign: 'right' }}>
        {t('FONT_SIZE')}
      </Typography>
      <Slider
        aria-label={t('FONT_SIZE')}
        value={settings.fontSize}
        getAriaValueText={(value) => `${value}`}
        valueLabelDisplay='on'
        valueLabelFormat={(value) => (value <= 11 ? t('PUBLISHER') : value.toString())}
        step={1}
        min={11}
        max={30}
        onChange={handleFontSizeChange}
        onMouseDown={onDragStart}
        onMouseUp={onDragEnd}
        onTouchStart={onDragStart}
        onTouchEnd={onDragEnd}
      />

      <Accordion
        variant='outlined'
        sx={{
          backgroundColor: 'transparent',
          elevation: 0,
          boxShadow: 'none',
          my: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='additional-settings-content'
          id='additional-settings-header'
        >
          <Typography>{t('LAYOUT')}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ border: 'none', pl: 6, pr: 2 }}>
          {sliderSettings.map((setting, index) => (
            <React.Fragment key={index}>
              <Typography variant='body1' sx={{ textAlign: 'right' }}>
                {setting.label}
              </Typography>
              <Slider
                aria-label={setting.label}
                value={setting.value}
                onChange={setting.onChange}
                step={setting.step}
                min={setting.min}
                max={setting.max}
                valueLabelDisplay='on'
                valueLabelFormat={(value) =>
                  value === setting.defaultValue ? t('PUBLISHER') : Math.round(value * 100) / 100
                }
                onMouseDown={onDragStart}
                onMouseUp={onDragEnd}
                onTouchStart={onDragStart}
                onTouchEnd={onDragEnd}
              />
            </React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>

      <Stack direction='row' spacing={2} sx={{ my: 2 }}>
        <FormControl fullWidth sx={{ my: 0 }}>
          <InputLabel id='font-family-select-label' variant='outlined'>
            {t('FONT_FAMILY')}
          </InputLabel>
          <TooltipFontFamilySelect
            labelId='font-family-select-label'
            id='font-family-select'
            value={settings.fontFamily}
            label={t('FONT_FAMILY')}
            onChange={(e) => updateSettings({ fontFamily: e.target.value as string })}
          >
            {googleFonts.map((font) => (
              <MenuItem key={font} value={font} sx={{ fontFamily: font, fontSize: '18px' }}>
                {font}
              </MenuItem>
            ))}
          </TooltipFontFamilySelect>
        </FormControl>

        <FormControl fullWidth sx={{ my: 0 }}>
          <InputLabel id='language-select-label' variant='outlined'>
            {t('LANGUAGE')}
          </InputLabel>
          <TooltipLanguageSelect
            labelId='language-select-label'
            id='language-select'
            value={prefs?.languageCode || navigator.language.split('-')[0] || 'en'}
            label={t('LANGUAGE')}
            onChange={(e) => handleLanguageChange(e.target.value as string)}
          >
            {LANGUAGES.map((language) => (
              <MenuItem key={language.code} value={language.code}>
                {language.name}
              </MenuItem>
            ))}
          </TooltipLanguageSelect>
        </FormControl>
      </Stack>

      <FormControl fullWidth sx={{ my: 0 }}>
        <InputLabel id='note-visibility-select-label'>{t('NOTE_VISIBILITY')}</InputLabel>
        <Select
          labelId='note-visibility-select-label'
          id='note-visibility-select'
          value={settings.noteVisibility}
          label={t('NOTE_VISIBILITY')}
          onChange={(e) => handleNoteVisibilityChange(e.target.value as BookSettings['noteVisibility'])}
        >
          <MenuItem value='none'>{t('NONE')}</MenuItem>
          <MenuItem value='onlyMine'>{t('ONLY_MINE')}</MenuItem>
          <MenuItem value='commentsUntilPassed'>{t('COMMENTS_HIDE_UNTIL_PASSED')}</MenuItem>
          <MenuItem value='comments'>{t('COMMENTS')}</MenuItem>
          <MenuItem value='allUntilPassed'>{t('ALL_HIDE_UNTIL_PASSED')}</MenuItem>
          <MenuItem value='all'>{t('ALL')}</MenuItem>
        </Select>
      </FormControl>

      <Grid container my={2}>
        <Grid item xs={12}>
          <Stack direction='row' spacing={2} justifyContent='space-between'>
            {alignmentButtons()}
            <FlowToggleButton value={settings.flow || 'paginated'} onChange={handleFlowChange} aria-label='Flow toggle'>
              {settings.flow === 'paginated' ? <SwapHorizIcon /> : <SwapVertIcon />}
            </FlowToggleButton>
            <FullscreenToggleButton
              disabled={isIOS && isMobileOnly}
              value={prefs?.fullscreen || false}
              onChange={handleFullscreenChange}
              aria-label='Fullscreen toggle'
            >
              {prefs?.fullscreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
            </FullscreenToggleButton>
            <ThemeToggleButton value={prefs?.theme || 'light'} onChange={handleThemeChange} aria-label='Theme toggle'>
              {prefs?.theme === 'light' ? (
                <LightModeIcon />
              ) : prefs?.theme === 'dark' ? (
                <DarkModeIcon />
              ) : (
                <Brightness6Icon />
              )}
            </ThemeToggleButton>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
        <Typography sx={{ alignContent: 'center' }}>{t('FAVORITE_SETTINGS')}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <LoadSettingsButton variant='contained' color='primary' onClick={handleLoadSettings}>
          {t('LOAD')}
        </LoadSettingsButton>
        <SaveSettingsButton variant='contained' color='secondary' onClick={handleSaveSettings}>
          {t('SAVE')}
        </SaveSettingsButton>
      </Stack>
    </Box>
  );
};

export default SettingsComponent;
