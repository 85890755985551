import { EpubCFI, type Contents, type NavItem, type Rendition, type Location as EpubLocation } from 'epubjs';
import type { CSSProperties } from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { defaultStyles, type IReaderStyle } from '../style';
import type {
  AnchorLocations,
  BookMeta,
  BookSettings,
  ChaptersInfo,
  Note,
  NoteCommentGroup,
  NotesGroup,
  RichLocation,
  UpdateRecord,
} from '../types/book';
import {
  adjustCfiRange,
  adjustFullscreenClass,
  calculateWPM,
  changeFullscreen,
  checkIfFullscreen,
  dedupe,
  extractCfiBase,
  getBook,
  getChapters,
  getCurrentUser,
  getNoteStatus,
  getPerson,
  getPrefs,
  getTheme,
  getTotalWPM,
  getTotalWordCount,
  getUpdates,
  handleBackNavigation,
  refreshAnnotations,
  reportOpen,
  setNoteStatus,
  updateAnnotation,
  updateBook,
  upgrade,
} from '../utils/books';
import { afterLayout, debounce, useThrottle } from '../utils/core';
import { calculateFileHash, getFile } from '../utils/indexedDB';
import { EpubView, type IEpubViewProps } from './EpubView';
import NoteBox from './NoteBox';
import TemporaryDrawer from './TemporaryDrawer';
import TopStatusBar from './TopStatusBar';
// import renditionCSS from '../rendition.css?url';
import { invertGrayscaleImages } from '../utils/image';
import { aiUser, hoursForRecentWPM } from '../config';
import { logger } from '../utils/logger';
import { isIOS, isMobileOnly } from 'react-device-detect';
import { checkAndAdjustStyles } from '../utils/styleAdjustment';
import { AppContext } from '../context/AppContext';
import { defaultBookSettings } from '../utils/defaults';
import { useReaderStore } from '../store/readerStore';
import { countComments, getFirstComment, getNotesEnclosingCFI } from '../utils/notes';
import { Dialog, DialogTitle, List, ListItemText, ListItemButton, ListItemAvatar, Avatar, Stack } from '@mui/material';
import { marked } from 'marked';
import { setViewport } from '../utils/viewports';

type ITextSelection = {
  text: string;
  cfiRange: string;
};

type TocItemProps = {
  data: NavItem;
  setLocation: (value: string) => void;
  styles?: CSSProperties;
};

const TocItem = ({ data, setLocation, styles }: TocItemProps) => (
  <div>
    <button onClick={() => setLocation(data.href)} style={styles}>
      {data.label}
    </button>
    {data.subitems && data.subitems.length > 0 && (
      <div style={{ paddingLeft: 10 }}>
        {data.subitems.map((item, i) => (
          <TocItem key={i} data={item} styles={styles} setLocation={setLocation} />
        ))}
      </div>
    )}
  </div>
);

export type IReaderProps = IEpubViewProps & {
  title?: string;
  showToc?: boolean;
  readerStyles?: IReaderStyle;
  // epubViewStyles?: IEpubViewStyle;
  swipeable?: boolean;
  nextPage?: () => void;
  prevPage?: () => void;
  settings?: BookSettings;
};

const doubleTapUpperBound = 250;

export const Reader = () => {
  const {
    prefs,
    changePrefs,
    effectiveTheme,
    setSnackbarMessage,
    checkAppVersion,
    setOnline,
    localForageLoaded,
    user,
    setUser,
  } = useContext(AppContext);
  const { id, uriLocation } = useParams<{ id: string; uriLocation: string }>(); // Assuming dynamic routing based on book ID

  const [initialLocation, setInitialLocation] = useState(uriLocation);

  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState<number>(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = (withTab?: number) => {
    if (isDrawerOpen && tempLocation) {
      readerRef.current?.display(tempLocation);
      setTempLocation(undefined);
    }
    const d = !isDrawerOpen;
    if (typeof withTab === 'number') {
      setOpenTab(withTab);
    }
    setIsDrawerOpen(d);
    setIsStatusBarVisible(d);
  };
  const toggleStatusBar = () => setIsStatusBarVisible(!isStatusBarVisible);
  const lastToggleStatusBarTimestamp = useRef(0);
  const userRef = useRef(user);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [isBookReadyForUpdate, setIsBookReadyForUpdate] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [allNotes, setAllNotes] = useState<NotesGroup>({});
  // Toggle function
  const [selections, setSelections] = useState<ITextSelection[]>([]);
  const [noteAnchorEl, setNoteAnchorEl] = useState<{
    getBoundingClientRect: () => DOMRect;
  } | null>(null);
  const [rendition, setRendition] = useState<Rendition | undefined>(undefined);
  const [fileURL, setFileURL] = useState<ArrayBuffer | string>('');
  const [book, setBook] = useState<BookMeta | undefined>(undefined);
  //   const { fontSize, lineSpacing, fontFamily } = settings.bookSettings;
  const [epubFile, setEpubFile] = useState<Blob | null>(null);
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(false);
  const bookRef = useRef(book);
  const allNotesRef = useRef(allNotes);
  const [tempLocation, setTempLocation] = useState<string | number | undefined>();
  const [overallWPM, setOverallWPM] = useState<number | undefined>();
  const [currentWPM, setCurrentWPM] = useState<number | undefined>();
  const [totalWordCount, setTotalWordCount] = useState<number | undefined>();
  const [chapterWordCount, setChapterWordCount] = useState<number[] | undefined>();
  const [canUpdate, setCanUpdate] = useState(true);
  const [usedBack, setUsedBack] = useState(false);
  const startIndexRef = useRef<number | undefined>(undefined);
  const historyRef = useRef<UpdateRecord | undefined>(undefined);
  const noteOpenTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isBookOpened, setIsBookOpened] = useState(false);
  const [readerStyle, setReaderStyle] = useState<IReaderStyle>();
  const readerRef = useRef<EpubView>(null);
  const loadingView = undefined;
  const readerStyles = readerStyle || defaultStyles;
  const props = {
    allowScriptedContent: true,
  };
  const [toc, setToc] = useState<NavItem[]>([]);
  const [chapterProgress, setChapterProgress] = useState<number | undefined>();
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const scrollHeightRef = useRef<number>(0);
  const [sectionContents, setSectionContents] = useState<Contents | undefined>();
  const [generatingLocations, setGeneratingLocations] = useState(false);
  const attempts = useRef(0);
  const firstDisplayTimeRef = useRef(0);
  const ignoreLocationChangeRef = useRef(false);
  const [lastCombinedStyle, setLastCombinedStyle] = useState<Record<string, any> | undefined>();
  const [isNoteBoxOpen, setIsNoteBoxOpen] = useState(false);
  const [overlappingNotes, setOverlappingNotes] = useState<Note[]>([]);
  const [isOverlappingDialogOpen, setIsOverlappingDialogOpen] = useState(false);
  const ignoreNextTapRef = useRef(false);
  const [insertedSpanId, setInsertedSpanId] = useState<string | null>(null);
  const sectionStepRef = useRef<string | null>(null);
  const [anchors, setAnchors] = useState<AnchorLocations | undefined>();
  const [chapterLocations, setChapterLocations] = useState<RichLocation[] | undefined>();
  const chaptersInfoRef = useRef<ChaptersInfo | undefined>();

  const handleToggleStatusBar = () => {
    const isFullscreen = checkIfFullscreen();

    if (false && Date.now() - lastToggleStatusBarTimestamp.current < doubleTapUpperBound) {
      //   if (prefs.fullscreen !== isFullscreen) {
      //     changeFullscreen(!isFullscreen);
      //   } else {
      //     changePrefs({ fullscreen: !isFullscreen });
      //   }
      toggleStatusBar();
      if (isIOS && isMobileOnly) {
        console.info('ios mobile, not changing fullscreen');
      } else {
        changePrefs({ fullscreen: !isFullscreen });
        changeFullscreen(!isFullscreen);
      }
      logger.debug('detected double tap, attempted to toggle fullscreen');
      lastToggleStatusBarTimestamp.current = 0;
    } else {
      if (isFullscreen !== prefs?.fullscreen && prefs?.fullscreen !== undefined) {
        lastToggleStatusBarTimestamp.current = 0;
        changeFullscreen(prefs?.fullscreen);
      } else {
        lastToggleStatusBarTimestamp.current = Date.now();
        toggleStatusBar();
      }
    }
  };

  useEffect(() => {
    if (localForageLoaded) {
      applyFlowClass();
    }
  }, [book?.settings.flow, localForageLoaded]);

  useEffect(() => {
    return () => {
      if (noteOpenTimeoutRef.current) {
        clearTimeout(noteOpenTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Push a new entry onto the history stack upon initial load
    window.history.pushState({}, '');

    const handleBack = (event: PopStateEvent): void => {
      logger.debug('Back navigation triggered');

      // Prevent the default back action
      event.preventDefault();
      window.history.pushState({}, '');

      handleBackNavigation(
        bookRef.current?.settings.updates || [],
        historyRef.current || [Date.now(), -1],
        setSnackbarMessage,
        goToPercentage,
        setUsedBack,
        historyRef
      );
    };

    window.addEventListener('popstate', handleBack);

    return () => window.removeEventListener('popstate', handleBack);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'hidden') {
        pushVisibleAt(false);
      } else if (document.visibilityState === 'visible') {
        pushVisibleAt(true);
        setOnline(navigator.onLine);
        await upgrade();
        checkAppVersion();
      }
    };

    if (localForageLoaded) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded) {
      addEventListener('fullscreenchange', onFullscreenChange);
    }

    return () => {
      removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, [localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded) {
      setUser(getCurrentUser());
    }
  }, [localForageLoaded]);

  useEffect(() => {
    if (initialLocation && isInitialized && localForageLoaded) {
      goToLocation(initialLocation);
      // Clear the location after using it
      setInitialLocation(undefined);
      // Optionally update the URL without the location
      navigate(`/read/${id}`, { replace: true });
    }
  }, [initialLocation, id, navigate, isInitialized, localForageLoaded]);

  // Load settings when the component mounts or when the id changes
  useEffect(() => {
    let active = true;
    if (localForageLoaded) {
      if (!id) {
        setIsBookOpened(false);
      }
      const checkHash = async () => {
        if (id) {
          const book = getBook(id);
          if (book && book.settings) {
            logger.debug('setting book initially', book);

            let updates: UpdateRecord[] = book.settings.updates || [];
            const serverBookUpdates = await getUpdates(id);
            if (serverBookUpdates.length > 0) {
              //   if (!book.settings.updates) {
              //     updates = serverBookUpdates;
              //   } else {
              // updates = book.settings.updates.concat(serverBookUpdates.filter((u: [number, number]) => !book.settings.updates.includes(u)));

              updates = dedupe([...serverBookUpdates, ...updates]);
              //   }
            }
            updates.sort((a, b) => a[0] - b[0] || b[1] - a[1]);

            if (!book.hash) {
              const bookFile = await getFile(id);
              if (bookFile) {
                const hash = await calculateFileHash(bookFile);
                logger.debug('calculated hash as ', hash);
                if (typeof hash === 'string') {
                  book.hash = hash;
                }
              }
            }
            logger.debug('first setting book after change of id, num updates = ', updates.length);
            setBook({
              ...book,
              timestamp: Date.now(),
              settings: { ...book.settings, updates: updates },
            });
          }
        }
      };
      checkHash();
    }
    return () => {
      active = false; // Prevent setting state on an unmounted component
    };
  }, [id, localForageLoaded]);

  useEffect(() => {
    let active = true; // Flag to manage cleanup

    const loadEpubFile = async () => {
      if (id && active) {
        const file = await getFile(id);
        if (!active) {
          return;
        }
        if (file) {
          file
            .arrayBuffer()
            .then((arrayBuffer) => {
              setFileURL(arrayBuffer);
            })
            .catch((error) => {
              logger.error('Error converting Blob to ArrayBuffer:', error);
            });
          //       const fileURL = URL.createObjectURL(file);
          //   setFileURL(fileURL); // Use the Blob URL

          // Cleanup function to revoke the Blob URL when the component unmounts
          return () => {
            // URL.revokeObjectURL(fileURL);
          };
        } else {
          logger.warn('could not open id', id);
          //   await deleteBook(id);
          //   navigate("/");
        }
      }
    };
    if (localForageLoaded) {
      loadEpubFile();
    }

    // Cleanup function for when the component unmounts or id changes
    return () => {
      active = false; // Prevent setting state on an unmounted component
    };
  }, [id, localForageLoaded]);

  useEffect(() => {
    if (id && user?.email && localForageLoaded) {
      const grabAllNotes = async () => {
        if (id) {
          const book = await reportOpen(id);
          if (book) {
            setIsBookOpened(true);
            setAllNotes(book.notes);
            setBook(book);
          } else {
            logger.error('could not open book', id);
          }
          //   redrawAnnotations();
        }
      };
      grabAllNotes();
    }
  }, [id, user?.email, localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded) {
      bookRef.current = book;
    }
  }, [book, localForageLoaded]);

  useEffect(() => {
    if (book && isBookReadyForUpdate && isBookOpened && localForageLoaded) {
      //   logger.debug("updating saved book info");
      //   useThrottle(() => updateBook(book), 500);
      //   debounce(() => updateBook(book), 500);
      updateBook(book);
    } else {
      logger.debug('not updating book');
    }
  }, [book, localForageLoaded]);

  //   useEffect(() => {
  //     if (bookRef.current?.overallScaleFactor) {
  //       logger.debug('overallScaleFactor already set, but not skipping: ', bookRef.current.overallScaleFactor);
  //       //   return;
  //     }
  //     (async () => {
  //       if (bookRef.current && rendition?.book) {
  //         const significantChapter = await findFirstSignificantChapter(rendition.book);
  //         const scaleFactor = await calculateSectionScaleFactor(rendition.book, significantChapter);
  //         const updatedBook = {
  //           ...bookRef.current,
  //           overallScaleFactor: scaleFactor,
  //         };
  //         logger.debug('setting overallScaleFactor to ', scaleFactor);
  //         updateBook(updatedBook);
  //       }
  //     })();
  //   }, [bookRef.current, rendition?.book]);

  useEffect(() => {
    if (localForageLoaded) {
      userRef.current = user;
    }
  }, [user, localForageLoaded]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (localForageLoaded) {
      timerId = setTimeout(() => {
        if (!userRef.current) {
          setSnackbarMessage({ text: 'Not currently logged in...', duration: 15000 });
        }
      }, 5000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [user, localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded) {
      allNotesRef.current = allNotes;
    }
  }, [allNotes, localForageLoaded]);

  useEffect(() => {
    if (book && totalWordCount && totalWordCount !== book.wordCount && localForageLoaded) {
      logger.debug('saving word count to book', totalWordCount);
      setBook({
        ...book,
        wordCount: Math.round(totalWordCount),
        chapterWordCount: chapterWordCount,
      });
    }

    updateWPM();
  }, [totalWordCount, chapterWordCount, localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded) {
      prefs?.theme && rendition && applySettings(rendition);
    }
  }, [prefs?.theme, localForageLoaded]);

  /**
   * Effect hook to handle changes in fullscreen preference.
   * When the fullscreen preference changes and the component is initialized,
   * it updates the fullscreen state and preferences accordingly.
   */
  useEffect(() => {
    if (localForageLoaded) {
      if (isIOS && isMobileOnly) {
        console.info('ios mobile, disabling fullscreen');
        if (prefs?.fullscreen) {
          changePrefs({ fullscreen: false });
        }
      } else {
        isInitialized && changeFullscreen(prefs?.fullscreen);
      }
    }
  }, [isInitialized, prefs?.fullscreen, document.visibilityState, localForageLoaded]);

  useEffect(() => {
    if (rendition && book && localForageLoaded) {
      const loc = book.settings.location;
      logger.debug('applying settings from useEffect', loc, rendition);
      if (book?.settings.flow && book.settings.flow !== rendition.settings.flow) {
        logger.debug('setting flow from ', rendition.settings.flow, 'to', book.settings.flow);
        if (book.settings.flow === 'scrolled') {
          readerRef.current?.getIframeDoc()?.querySelector('body')?.classList.add('scroll-flow');
        } else {
          readerRef.current?.getIframeDoc()?.querySelector('body')?.classList.remove('scroll-flow');
        }
        rendition.flow(book.settings.flow);
      }

      applySettings(rendition)
        .then(() => redrawAnnotations())
        .finally(() => {
          if (tempLocation) {
            if (typeof tempLocation === 'number') {
              rendition.display(tempLocation);
            } else {
              rendition.display(tempLocation);
            }
          }
        });
    }
  }, [
    book?.settings.fontSize,
    book?.settings.lineSpacing,
    book?.settings.fontFamily,
    book?.settings.fontWeight,
    book?.settings.alignment,
    book?.settings.margin,
    book?.settings.flow,
    book?.settings.indent,
    book?.settings.marginY,
    book?.settings.allImportant,
    localForageLoaded,
    book?.settings.noteVisibility,
  ]);

  //   useEffect(() => {
  //     if (localForageLoaded && book?.settings.noteVisibility) {
  //       redrawAnnotations();
  //     }
  //   }, [book?.settings.noteVisibility]);

  useEffect(() => {
    // const docElement = readerRef.current?.getIframeDoc()?.documentElement;
    // let bestGuessFontSize: number | undefined;
    // if (docElement) {
    //   bestGuessFontSize = parseFloat(getComputedStyle(docElement).fontSize);
    // } else {
    //   bestGuessFontSize = undefined;
    // }
    let bestGuessFontSize = undefined;
    if (localForageLoaded) {
      useReaderStore.setState({
        lineSpacing:
          book?.settings.lineSpacing === defaultBookSettings.lineSpacing ? undefined : book?.settings.lineSpacing,
        fontSize:
          book?.settings.fontSize === defaultBookSettings.fontSize ? bestGuessFontSize : book?.settings.fontSize,
      });
    }
  }, [book?.settings.lineSpacing, book?.settings.fontSize, localForageLoaded]);

  useEffect(() => {
    if (localForageLoaded && book && overallWPM && book.wpm !== overallWPM) {
      book.wpm = overallWPM;
      book.wpmTimestamp = Date.now();
      updateBook(book);
    }
  }, [overallWPM, localForageLoaded]);

  const goToLocation = (loc: string) => {
    logger.debug('gotoLocation');
    if (!loc) return;

    // Example logic to determine the type of location and act accordingly
    const perc = loc === '100' ? 1.0 : parseFloat('0.' + loc);
    goToPercentage(perc);
  };

  const goToPercentage = (percentage: number) => {
    logger.debug(`Going to ${percentage}% of the book`);
    readerRef.current?.display(percentage);
    // Implement the logic to navigate to this percentage of the book
  };

  const nextPage = () => {
    rendition?.next();
  };

  const clearAnnotations = (rendition: Rendition | undefined, cfis: string[]) => {
    cfis.forEach((cfi) => {
      rendition?.annotations.remove(cfi, 'highlight');
    });
    logger.debug('cleared annotations');
  };

  /**
   * Applies annotations to the current rendition.
   *
   * @param rendition - The optional Rendition object to apply annotations to.
   * @param notes - The optional NotesGroup object containing the annotations to apply.
   *
   * @description
   * This function filters and processes the provided notes, then applies them as annotations
   * to the current rendition. It handles cases where notes might have comments or need to be
   * converted to a new format. If there are any errors during the process, they are logged,
   * but the function continues execution.
   *
   * The function performs the following steps:
   * 1. Checks if the necessary components (rendition, id, notes) are available.
   * 2. Filters annotations to remove duplicates, preferring newer and owned annotations.
   * 3. Converts old-style comments to the new format if necessary.
   * 4. Applies each annotation to the rendition with appropriate styling.
   *
   * @throws {Error} Logs an error if there's an issue applying annotations, but doesn't stop execution.
   */
  const applyAnnotations = async (rendition?: Rendition, notes?: NotesGroup) => {
    const rend = readerRef.current?.rendition;
    if (id && rend && notes) {
      try {
        const filteredAnnotations = Object.values(notes).reduce((acc: NotesGroup, note) => {
          const { id, owner, timestamp } = note;
          if (
            !acc[id] ||
            (!acc[id].owner && owner) ||
            (acc[id].owner && owner && id.length > acc[id].id.length) ||
            (acc[id].owner && owner && timestamp > acc[id].timestamp)
          ) {
            acc[id] = note;
          }
          return acc;
        }, {});

        Object.values(filteredAnnotations).forEach((note) => {
          if (note.comment && (!note.comments || Object.keys(note.comments).length === 0)) {
            note.comments = {
              [note.id]: {
                text: note.comment,
                owner: note.owner,
                timestamp: new Date().toISOString(),
                id: crypto.randomUUID(),
                reactions: {},
              },
            };
          }
        });
        logger.debug('filteredAnnotations', filteredAnnotations);

        //call updateAnnotation on each note
        Object.values(filteredAnnotations).forEach((note) => {
          updateAnnotation(rend.annotations, note, bookRef.current, undefined);
        });

        // Object.values(filteredAnnotations).forEach((note) => {
        //   rend.annotations.add(
        //     "highlight",
        //     note.cfiRange,
        //     note,
        //     undefined,
        //     note.comments && Object.keys(note.comments).length > 0 ? "wr-hlc" : "wr-hl",
        //     { stroke: note.color, fill: note.color, "fill-opacity": "0.3" }
        //   );
        // });
      } catch (e) {
        logger.error('error applying annotations', e, 'ann =', notes, '...continuing.');
      }
      logger.debug('applied annotations', rend.annotations);
    } else {
      logger.log('no rendition or notes when trying to applyAnnotations');
    }
  };

  const prevPage = () => {
    rendition?.prev();
  };

  const updateSettings = (newSettings: Partial<BookSettings>, currentLocation?: string | number) => {
    if (id && book && isBookReadyForUpdate) {
      if (currentLocation && !tempLocation) {
        setTempLocation(currentLocation);
      }
      let updated = false;
      if (newSettings.progress && canUpdate && !usedBack) {
        logger.debug('updating updates', newSettings, book);
        const updates = book.settings.updates || [];
        updates.push([Date.now(), newSettings.progress]);
        newSettings = { ...newSettings, updates: updates };
        setCanUpdate(false);
        setTimeout(() => {
          setCanUpdate(true);
        }, 30000);
        updated = true;
      }
      if (usedBack) {
        setUsedBack(false);
      } else if (historyRef.current && newSettings.progress && newSettings.progress !== historyRef.current[1]) {
        historyRef.current = undefined;
      }
      setBook(
        (prevBook) =>
          (prevBook && {
            ...prevBook,
            timestamp: Date.now(),
            settings: { ...prevBook.settings, ...newSettings },
          }) ||
          prevBook
      );
      if (updated) {
        updateWPM();
        updateBook(book);
      }
    }
  };

  const handleTextSelected = (cfiRange: string, contents: Contents) => {
    if (readerRef.current?.selecting) {
      logger.debug('really, selecting already');
      //   return;
    } else {
      logger.debug('done selecting');
    }
    const rendition = readerRef.current?.rendition;
    if (rendition) {
      setSelections((list) =>
        list.concat({
          text: rendition.getRange(cfiRange, 'epubjs-ignore').toString(),
          cfiRange,
        })
      );
      rendition.annotations.add('highlight', cfiRange, {}, undefined, 'hl', {
        fill: 'red',
        'fill-opacity': '0.5',
        'mix-blend-mode': 'multiply',
      });
      const selection = contents.window.getSelection();
      logger.debug('selection', selection);
      selection?.removeAllRanges();
    } else {
      logger.debug('bailing, rendition=', rendition, contents, cfiRange);
    }
  };

  const handleOnRelocated = (newLocation: EpubLocation, oldLocation?: EpubLocation, targetLoc?: 'next' | 'prev') => {
    const cfi = newLocation.start.cfi;
    const step = cfi.slice(8).split('!')?.[0];
    if (step !== sectionStepRef.current) {
      sectionStepRef.current = step;
      logger.debug('sectionStep', sectionStepRef.current);
    }
    if (ignoreLocationChangeRef.current) {
      logger.debug('ignoring location change due to flag');
      return;
    }

    const giveUpAfterMs = 3000;
    // logger.debug(
    //   "handleOnRelocated newLocation=",
    //   newLocation,
    //   "oldLocation=",
    //   oldLocation,
    //   "targetLoc=",
    //   targetLoc,
    //   "startcfi=",
    //   newLocation.start.cfi,
    //   "endcfi=",
    //   newLocation.end.cfi,
    //   "targetLoc=",
    //   bookRef.current?.settings.location
    // );

    if (id) {
      const idx = newLocation.start.index;
      //   const idx = readerRef.current?.rendition?.location?.start?.index;
      if (idx) {
        startIndexRef.current = idx;
      }

      const loc = newLocation.start.cfi;
      const prog = newLocation.start.percentage;
      //   const savedLoc = bookRef.current?.settings.location;

      //   if (!savedLoc || !loc) {
      //     logger.warn("no targetLoc or loc on handleOnRelocated", savedLoc, loc, newLocation);
      //     return;
      //   }
      //   const isInRange = newLocation.start.cfi <= targetLoc && targetLoc <= newLocation.end.cfi;
      //   const isInRange =
      //     EpubCFI.prototype.isCfiString(savedLoc) &&
      //     EpubCFI.prototype.compare(newLocation.start.cfi, savedLoc) <= 0 &&
      //     EpubCFI.prototype.compare(savedLoc, newLocation.end.cfi) <= 0;

      // if the target is an href, or if it's a cfi between start and end, then we're good
      if (!isBookReadyForUpdate) {
        logger.debug('is not ready for update, skipping');
        return;
      }

      //   if (
      //     targetLoc ||
      //     !EpubCFI.prototype.isCfiString(savedLoc) ||
      //     isInRange ||
      //     (firstDisplayTimeRef.current && Date.now() - firstDisplayTimeRef.current > giveUpAfterMs)
      //   ) {
      // logger.debug("isInRange", isInRange, "savedLoc", savedLoc, "newLocation", newLocation);
      // firstDisplayTimeRef.current = 0;
      if (!tempLocation) {
        setChapterProgress(readerRef.current?.currentChapterProgress());
        if (prog) {
          updateSettings({ location: loc, progress: prog });
        } else {
          updateSettings({ location: loc });
        }
      }
      //   } else {
      //     logger.info(
      //       "seemed to have missed, trying again, startcfi=",
      //       newLocation.start.cfi,
      //       "targetLoc=",
      //       savedLoc,
      //       "endcfi=",
      //       newLocation.end.cfi
      //     );
      //     if (!firstDisplayTimeRef.current) {
      //       firstDisplayTimeRef.current = Date.now();
      //     }
      // readerRef.current?.display(savedLoc);
      //   }
      //   if (prefsRef.current?.fullscreen !== undefined) {
      //     changeFullscreen(prefsRef.current?.fullscreen);
      //   }
    }
  };

  const setLocation = (value: string) => {
    if (id) {
      updateSettings({ location: value });
    }
  };

  function serializeCSSObject(cssObject: Record<string, any>): string {
    let cssString = '';

    for (const [selector, styles] of Object.entries(cssObject)) {
      cssString += `${selector} {\n`;
      for (const [property, value] of Object.entries(styles as Record<string, string>)) {
        if (value !== '') {
          cssString += `  ${property.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value};\n`;
        }
      }
      cssString += '}\n';
    }

    return cssString;
  }

  let initializing = false;

  const doSettings = async () => {
    const rendition = readerRef.current?.rendition;
    const book = bookRef.current;
    if (!rendition || !book) {
      return;
    }

    if (!chaptersInfoRef.current) {
      const chaptersInfo = await getChapters(book);
      chaptersInfoRef.current = chaptersInfo;
    }
    const { sections } = chaptersInfoRef.current;
    const richSection = sections.find((s) => s.base === extractCfiBase(bookRef.current?.settings.location));
    const primaryFontSize = richSection?.fontSize || 16;
    logger.debug('primaryFontSize', primaryFontSize);

    const initialStyle = createInitialStyle(book.settings, bookRef.current?.primaryFontSize || primaryFontSize);

    applyThemeColors(rendition, getTheme());

    if (!isInitialized) {
      rendition.themes.register('custom', initialStyle);
      rendition.themes.select('custom');
    }
    let doc = readerRef.current?.getIframeDoc();
    if (!doc) {
      const waitForIframe = async () => {
        return new Promise<Document>((resolve) => {
          const checkIframe = () => {
            let doc = readerRef.current?.getIframeDoc();
            if (doc) {
              resolve(doc);
            } else {
              afterLayout(() => {
                checkIframe();
              });
            }
          };
          checkIframe();
        });
      };
      doc = await waitForIframe();
    }

    if (doc && bookRef.current) {
      logger.info('applying adjust');
      let adjustedStyle = await checkAndAdjustStyles(
        doc,
        bookRef.current.settings,
        getPrefs(),
        bookRef.current,
        'epubjs-ignore'
      );
      const combinedStyle = {
        ...(lastCombinedStyle || initialStyle),
        ...adjustedStyle,
        '#epub-content-wrapper': {
          padding: `0 ${Math.max(bookRef.current.settings.margin || 0, 0) * 4}px`,
        },
        // body: { ...initialStyle.body },
      } as Record<string, any>;
      (combinedStyle.body.fontSize = `${
        !book.settings.fontSize || book.settings.fontSize === defaultBookSettings.fontSize
          ? '1em'
          : `${book.settings.fontSize / (bookRef.current?.primaryFontSize || primaryFontSize)}em !important`
      }`),
        setLastCombinedStyle(combinedStyle);
      const combinedStyleString = serializeCSSObject(combinedStyle);
      //   if (combinedStyle !== lastCombinedStyle) {
      //     setLastCombinedStyle(combinedStyle);
      //     setTimeout(() => {
      //       setLastCombinedStyle('');
      //     }, 5000);
      //   }
      //   logger.info('initialValue=', initialStyle, ' serial=', combinedStyle);
      rendition.themes.registerCss('custom', combinedStyleString);
      rendition.themes.select('custom');
    } else {
      logger.info('skipping, no doc');
    }
  };

  const applySettings = async (rend: Rendition) => {
    const rendition = readerRef.current?.rendition;
    if (!rendition || !book) {
      return;
    }

    // Apply initial settings
    if (isInitialized) {
      doSettings();
    }

    // Wait for the content to be rendered
    // continue waiting until getIframeDoc() is defined
    // let i = 0;
    // while (!readerRef.current?.getIframeDoc() && ++i < 10) {
    //   await new Promise((resolve) => setTimeout(resolve, 250));
    //   logger.debug('still waiting...');
    // }
    // logger.debug('displayed', rendition);

    // Check if settings were applied correctly and adjust if necessary
    // const theme = prefsRef.current?.theme as 'light' | 'dark';
    // let doc = readerRef.current?.getIframeDoc();
    // let adjustedStyle = {};
    // if (!doc) {
    //   logger.warn('no readerRef doc', readerRef.current);
    // } else {
    //   adjustedStyle = await checkAndAdjustStyles(doc, book.settings, prefsRef.current);

    //   // // Ensure proper contrast for text color
    //   //   const backgroundColor = defaultStyles[theme]?.readerArea?.backgroundColor || '#ffffff'; // Provide a fallback
    //   //   const defaultTextColor = defaultStyles[theme]?.readerArea?.color || '#000000'; // Provide a fallback

    //   //   // Adjust text color for proper contrast
    //   //   Object.keys(adjustedStyle).forEach((selector) => {
    //   //     if (adjustedStyle[selector].color) {
    //   //       adjustedStyle[selector].color = ensureContrast(
    //   //         adjustedStyle[selector].color,
    //   //         backgroundColor,
    //   //         defaultTextColor
    //   //       );
    //   //     }
    //   //   });
    //   // }

    //   const combinedStyle = serializeCSSObject({
    //     ...initialStyle,
    //     ...adjustedStyle,
    //     body: { ...initialStyle.body },
    //   });
    //   if (combinedStyle !== lastCombinedStyle) {
    //     setLastCombinedStyle(combinedStyle);
    //     rendition.themes.registerCss('custom', combinedStyle);
    //     rendition.themes.select('custom');
    //   }

    //   logger.info('combined style ', combinedStyle);
    // }

    // Apply color adjustments for theme
    // applyThemeColors(rendition, theme as 'light' | 'dark');

    // Redraw annotations and adjust fullscreen class
    // redrawAnnotations();
    // adjustFullscreenClass();

    if (!isInitialized) {
      initializing = true;
      logger.debug('is not initialized, displaying null');
      readerRef.current?.display(null);

      hereBeDragonsWrapper(
        () => {
          logger.debug('display 1', book.settings?.location, bookRef.current?.settings.progress);
          doSettings();
          readerRef.current?.display(null);
          //   readerRef.current?.display(book.settings?.location || bookRef.current?.settings.progress || null);
          setTimeout(() => {
            logger.debug('display 2', book.settings?.location, bookRef.current?.settings.progress);
            readerRef.current?.display(null);

            // readerRef.current?.display(book.settings?.location || bookRef.current?.settings.progress || null);
          }, 1000);
        },

        () => {
          // if (!isBookReadyForUpdate) {
          //     readerRef.current
          // }
          //   readerRef.current?.display(book.settings?.location || bookRef.current?.settings.progress || null);

          logger.debug('display 3', book.settings?.location, bookRef.current?.settings.progress);
          //   setIsInitialized(true);
          readerRef.current?.display(null);
          // Wait for the iframe document to be ready before applying settings
          //   const waitForIframe = async () => {
          //     return new Promise<void>((resolve) => {
          //       const checkIframe = () => {
          //         if (readerRef.current?.getIframeDoc()) {
          //           resolve();
          //         } else {
          //           afterLayout(() => {
          //             checkIframe();
          //           });
          //         }
          //       };
          //       checkIframe();
          //     });
          //   };

          //   const wait = async () => {
          //     if (!doc) {
          //       await waitForIframe();
          //       doc = readerRef.current?.getIframeDoc();
          //     }

          setIsBookReadyForUpdate(true);
          //   readerRef.current?.display(
          //     null
          //     // (bookRef.current?.settings.flow === "paginated"
          //     //   ? bookRef.current?.settings.progress || bookRef.current?.settings.location
          //     //   : bookRef.current?.settings.progress || bookRef.current?.settings.location) || null
          //   );
        },
        2500,
        750
      );
    } else {
      logger.debug('calling display with tempLocation', tempLocation);
      readerRef.current?.display(tempLocation || null);

      //   readerRef.current?.display(tempLocation || book.settings?.progress || book.settings?.location || null);
    }
    logger.debug('did apply settings');
  };

  const createInitialStyle = (settings: BookSettings, primaryFontSize: number) => {
    // const { sections } = chaptersInfoRef.current;
    // const richSection = sections.find((s) => s.base === extractCfiBase(bookRef.current?.settings.location));
    // const primaryFontSize = richSection?.fontSize || 16;
    // Create initial style object based on settings
    const styleSection = {
      'font-family': settings.fontFamily === 'default' ? 'inherit' : `${settings.fontFamily}`,
      //   'font-size': settings.fontSize && settings.fontSize <= 11 ? `inherit` : `${settings.fontSize}px`,
      //   'font-size': '1em',
      'font-weight': settings.fontWeight === 0 ? `normal` : `${`${settings.fontWeight}`}`,
      'line-height': settings.lineSpacing === 0.95 ? `normal` : `${settings.lineSpacing?.toFixed(2)}`,
      //   'padding-left': settings.margin === -1 ? '' : `${(settings.margin || 0) * 4}px`,
      //   'padding-right': settings.margin === -1 ? '' : `${(settings.margin || 0) * 4}px`,
      'text-align': settings.alignment ? `${settings.alignment}` : ``,
      'margin-top': settings.marginY === -0.1 ? '' : `${settings.marginY}em`,
      'margin-bottom': settings.marginY === -0.1 ? '' : `${settings.marginY}em`,
      'text-indent': settings.indent === -0.25 ? '' : `${settings.indent}em`,
    };
    let style: Record<string, any> = {
      body: {},
      p: styleSection,
    };
    // const padX = settings.margin === -1 ? undefined : `{settings.margin * 4}px !important`;
    // if (padX !== undefined) {
    //   style.body.paddingLeft = padX;
    //   style.body.paddingRight = padX;
    // }
    style = {
      ...style,
      body: {
        ...style.body,
        paddingTop: '16px !important',
        paddingBottom: '16px !important',
        paddingLeft: `0`,
        paddingRight: `0`,
        margin: `0 !important`,
        fontSize: `${
          !settings.fontSize || settings.fontSize === defaultBookSettings.fontSize
            ? '1em !important'
            : // : `${settings.fontSize / parseFloat(getComputedStyle(document.documentElement).fontSize || '16px')}em`
              `${settings.fontSize / primaryFontSize}em !important`
        }`,
        // backgroundColor: `${getTheme() === 'light' ? `#fff` : `#000`} !important`,
      },
      '#epub-content-wrapper': {
        padding: `0 ${Math.max(settings.margin || 0, 0) * 4}px`,
      },
    };
    return style;
  };

  const applyThemeColors = (rendition: Rendition, theme: 'light' | 'dark') => {
    // Apply theme-specific color adjustments
    if (!document.body.classList.contains(theme)) {
      document.body.classList.remove('dark', 'light');
      document.body.classList.add(theme);
    }
    if (document.body.parentElement) {
      document.body.parentElement.style.backgroundColor = theme === 'light' ? `#fff` : `#000`;
      document.body.parentElement.style.color = theme === 'light' ? `#000` : `#fff`;
      const metaArray = Array.from(document.head.getElementsByTagName('meta'));
      const themeMeta = metaArray.find((meta) => meta.getAttribute('name') === 'theme-color');
      if (themeMeta) {
        themeMeta.setAttribute('content', theme === 'light' ? `#fff` : `#000`);
      } else {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'theme-color');
        meta.setAttribute('content', theme === 'light' ? `#fff` : `#000`);
        document.head.appendChild(meta);
      }
    }
    const doc = readerRef.current?.getIframeDoc();
    if (doc) {
      doc.body.classList.remove('light', 'dark');
      doc.body.classList.add(theme);
    }
    document.documentElement.style.setProperty('--blendmode', theme === 'light' ? 'darken' : 'lighten');
  };

  const hereBeDragonsWrapper = async (f: () => any, g: () => any, delay: number, fade: number) => {
    document.body.style.transition = '';
    document.body.style.opacity = '0';

    f();

    setTimeout(() => {
      g();
      document.body.style.transition = `opacity ${fade}ms ease-in-out`;
      document.body.style.opacity = '1';
      setTimeout(() => {
        document.body.style.transition = '';
      }, fade);
    }, delay);
  };

  const applyFlowClass = () => {
    const flow = bookRef.current?.settings.flow;
    const doc = document.querySelector('.epub-container');
    if (flow && doc) {
      const flowClasses = ['scrolled', 'paginated'];

      if (!doc.classList.contains(flow)) {
        flowClasses.forEach((className) => {
          if (className === flow) {
            document.body.classList.add(className);
            doc.classList.add(className);
          } else {
            document.body.classList.remove(className);
            doc.classList.remove(className);
          }
        });
      }
    }
  };

  const onFullscreenChange = () => {
    logger.debug('fullscreen change detected', document);
    afterLayout(() => {
      if (readerRef.current?.rendition) {
        adjustFullscreenClass();
        const isFullscreen = checkIfFullscreen();
        const isScrolled = bookRef.current?.settings.flow === 'scrolled';
        const container = document.querySelector('.epub-container') as HTMLDivElement;

        if (isFullscreen) {
          readerRef.current?.getIframeDoc()?.querySelector('body')?.classList.add('fullscreen');
          container?.scrollBy({ top: -52, behavior: 'instant' });
        } else {
          readerRef.current?.getIframeDoc()?.querySelector('body')?.classList.remove('fullscreen');
          container?.scrollBy({ top: 52, behavior: 'instant' });
        }

        const style = getComputedStyle(document.documentElement);
        //   logger.debug("computed style = ", style);
        const safeAreaInsetBottom = isScrolled ? 0 : parseInt(style.getPropertyValue('safe-area-inset-bottom')) || 0;
        const safeAreaInsetTop = isScrolled ? 0 : parseInt(style.getPropertyValue('safe-area-inset-top')) || 0;
        const desiredHeight = window.innerHeight - safeAreaInsetBottom - safeAreaInsetTop;
        // (prefs?.fullscreen ? 50 : 0);
        const desiredWidth = window.innerWidth;
        logger.debug(
          'resizing after fullscreen',
          readerRef.current?.rendition,
          safeAreaInsetTop,
          safeAreaInsetBottom,
          desiredWidth,
          desiredHeight
        );

        applyFlowClass();
        applySettings(readerRef.current?.rendition).then(() => redrawAnnotations());

        if (container && isScrolled) {
          if (container.style.height !== `${desiredHeight}`) {
            logger.debug('set style from height to height', container.style.height, desiredHeight);
            container.style.height = `${desiredHeight}px`;
          }
        }
      }
    });
  };

  const handleScrolled = () => {};

  const getRendition = (newRendition: Rendition) => {
    setRendition(newRendition);
    logger.debug("updated Reader's rendition", newRendition);
  };

  const addNote = (cfiRange: string, color: string, text: string, comments?: NoteCommentGroup) => {
    if (!book) {
      logger.error('no book??');
      return;
    }
    if (book.notes === undefined) {
      book.notes = {};
    }
    const timestamp = new Date().toISOString();
    const newId = crypto.randomUUID();
    const newNote = {
      cfiRange: cfiRange,
      color: color,
      text: text,
      timestamp: timestamp,
      id: newId,
      owner: user?.email || '',
      comments: comments || {},
      progress:
        readerRef.current?.rendition?.book.locations?.percentageFromCfi(cfiRange) || book.settings.progress || 0,
    };

    // Set the note status as read immediately when created
    setNoteStatus(newId, { read: true, hidden: false });

    setBook((prevBook) => {
      if (!prevBook) {
        logger.error('Book is undefined or has no valid id.');
        return prevBook;
      }

      return {
        ...prevBook,
        notes: {
          ...prevBook.notes,
          [newId]: newNote,
        },
      };
    });
    updateBook(book, true);
    readerRef.current?.rendition?.annotations.add(
      'highlight',
      cfiRange,
      newNote,
      undefined,
      comments ? 'wr-hlc' : 'wr-hl',
      { stroke: color, fill: color, 'fill-opacity': '0.3' }
    );
    logger.debug('addNote: annotation added', cfiRange, color, text);
  };

  const redrawAnnotations = async (onlyReveal = false) => {
    if (readerRef.current?.rendition?.annotations && bookRef.current?.notes) {
      refreshAnnotations(
        readerRef.current.rendition.annotations,
        bookRef.current.notes,
        bookRef.current,
        sectionStepRef.current,
        readerRef.current?.getIframeDoc(),
        onlyReveal
      );
    } else {
      logger.error('no annotations or notes in rendition: ', readerRef.current?.rendition, bookRef.current);
    }
    const doc = readerRef.current?.getIframeDoc();
    const theme = getTheme();
    if (doc && theme && !doc.body.classList.contains(theme)) {
      doc.body.classList.remove('light', 'dark');
      doc.body.classList.add(theme);
    }
  };

  const handleScrollHeightChange = useCallback(
    debounce(() => {
      const iframeDoc = readerRef.current?.getIframeDoc();
      if (iframeDoc) {
        const newScrollHeight = iframeDoc.documentElement.scrollHeight;
        if (newScrollHeight !== scrollHeightRef.current) {
          const oldHeight = scrollHeightRef.current || 0;
          scrollHeightRef.current = newScrollHeight;
          logger.log('Iframe scroll height changed to:', newScrollHeight);
          requestAnimationFrame(() => {
            const epubViewDiv = document.querySelector('.epub-view') as HTMLElement;
            const epubViewIframe = document.querySelector('.epub-view iframe') as HTMLElement;
            const epubContainer = document.querySelector('.epub-container') as HTMLElement;
            let heightDifference = 0;
            if (epubViewDiv) {
              const computedHeight = parseInt(getComputedStyle(epubViewDiv).height, 10);
              heightDifference = newScrollHeight - computedHeight;
              if (Math.abs(heightDifference) > 0) {
                epubViewDiv.style.height = `${newScrollHeight}px`;
              }
            }
            if (epubViewIframe) {
              const computedHeightIframe = parseInt(getComputedStyle(epubViewIframe).height, 10);
              if (Math.abs(newScrollHeight - computedHeightIframe) > 5) {
                epubViewIframe.style.height = `${newScrollHeight}px`;
              }
            }
            if (epubContainer && Math.abs(heightDifference) > 0) {
              const containerScrollTop = epubContainer.scrollTop;
              const containerHeight = epubContainer.clientHeight;
              if (containerScrollTop < heightDifference) {
                epubContainer.scrollBy({ top: -heightDifference, behavior: 'instant' });
              } else if (containerScrollTop + containerHeight + heightDifference > newScrollHeight) {
                epubContainer.scrollBy({ top: heightDifference, behavior: 'instant' });
              }
            }
            redrawAnnotations();
          });
        }
      }
    }, 30),
    []
  );

  useEffect(() => {
    const currentReader = readerRef.current;
    if (localForageLoaded && currentReader && isInitialized && sectionContents) {
      const setupResizeObserver = () => {
        const iframeDoc = currentReader.getIframeDoc();
        if (iframeDoc) {
          if (resizeObserverRef.current) {
            resizeObserverRef.current.disconnect();
          }
          resizeObserverRef.current = new ResizeObserver(() => {
            requestAnimationFrame(handleScrollHeightChange);
          });
          resizeObserverRef.current.observe(iframeDoc.documentElement);
        }
      };

      // Initial setup attempt
      setupResizeObserver();

      // Retry setup periodically if iframe is not immediately available
      const intervalId = setInterval(() => {
        if (!resizeObserverRef.current) {
          setupResizeObserver();
        } else {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
        // Cancel the debounced function
        handleScrollHeightChange.cancel();
      };
    }
  }, [handleScrollHeightChange, isInitialized, sectionContents, prefs?.fullscreen, localForageLoaded]);

  const updateNote = async (revisedNote: Note) => {
    if (!book) {
      logger.error('updateNote: book is undefined');
      return;
    }

    const newBook = {
      ...book,
      notes: {
        ...book.notes,
        [revisedNote.id]: revisedNote,
      },
    };
    logger.debug('updateNote: newBook', newBook);
    await updateBook(newBook, true);

    setBook(getBook(newBook.id));
    if (readerRef.current?.rendition?.annotations) {
      updateAnnotation(
        readerRef.current.rendition.annotations,
        revisedNote,
        bookRef.current,
        readerRef.current.getIframeDoc(),
        true
      );
    } else {
      logger.error('no annotations in rendition: ', readerRef.current?.rendition);
    }
  };

  const handleMarkLongPress = async (cfirange: EpubCFI, data: any, contents: Contents) => {};

  const handleMarkClicked = async (cfirange: EpubCFI, data: any, contents: Contents) => {
    const chosenNote = await getProperNote(cfirange, data, contents);
    if (!chosenNote) {
      return;
    }

    handleSelectedNote(chosenNote, contents);
  };

  const getProperNote = async (cfirange: EpubCFI, data: any, contents: Contents): Promise<Note | undefined> => {
    if (ignoreNextTapRef.current) {
      logger.debug('ignoring tap in handleMarkClicked, setting false');
      ignoreNextTapRef.current = false;
      return;
    }

    logger.debug('markClicked', cfirange, data, book);
    ignoreNextTapRef.current = true;
    setTimeout(() => {
      ignoreNextTapRef.current = false;
    }, 300);
    logger.debug('ignoreNextTapRef', ignoreNextTapRef.current);
    const currentCfiRange = data.cfiRange;
    const notes = Object.fromEntries(Object.entries(bookRef.current?.notes || {}).filter(([_, note]) => !note.deleted));

    const overlappingNotes = getNotesEnclosingCFI(currentCfiRange, notes);

    if (Object.keys(overlappingNotes).length > 1) {
      setOverlappingNotes(Object.values(overlappingNotes));
      setIsOverlappingDialogOpen(true);

      const selectedNote = await new Promise<Note | null>((resolve) => {
        const handleNoteSelect = (note: Note) => {
          setIsOverlappingDialogOpen(false);
          resolve(note);
        };

        const handleCancel = () => {
          setIsOverlappingDialogOpen(false);
          resolve(null);
        };

        // The Dialog component will be rendered based on isOverlappingDialogOpen state
        // The resolve function will be called when a note is selected or the dialog is cancelled
      });

      if (selectedNote === null) {
        return; // User canceled the selection
      }

      return selectedNote;
    } else {
      const clickedNote = Object.values(overlappingNotes)[0];
      if (!clickedNote) {
        logger.error('Selected note not found', cfirange, contents, data);
        return;
      }
      return clickedNote;
    }
  };

  const clearSpan = (noteId: string) => {
    const doc = readerRef.current?.getIframeDoc();
    const existingSpan = doc?.getElementById(`note-marker-${noteId}`);

    if (existingSpan) {
      existingSpan.remove();
      redrawAnnotations();
    } else {
      logger.info('no span to clear for ', noteId, ' and doc ', doc);
    }
  };

  const handleSelectedNote = (note: Note, contentsIn?: Contents) => {
    const contents =
      contentsIn || sectionContents || ((readerRef.current?.rendition?.getContents() as any)?.[0] as Contents);
    contents.document.normalize();
    const adjustedCfiRange = adjustCfiRange(note.cfiRange, contents.document);
    const range = new EpubCFI(adjustedCfiRange).toRange(contents.document, 'epubjs-ignore');

    if (range) {
      const firstComment = getFirstComment(note);
      const isMultiline = firstComment?.text.trim().includes('\n') || false;

      if (
        !firstComment ||
        countComments(note) !== 1 ||
        (firstComment.owner === aiUser.email && firstComment.text.length > 30) ||
        isMultiline
      ) {
        readerRef.current?.selectByCfi(adjustedCfiRange);
        setSelectedNote(note);
        logger.debug('open note box');
      } else {
        // Check if there's already a span for this note
        const existingSpan = contents.document.getElementById(`note-marker-${note.id}`);

        if (existingSpan) {
          // If the span exists, remove it
          clearSpan(note.id);
          readerRef.current?.selectByCfi(adjustedCfiRange);
          setSelectedNote(note);
        } else {
          //   const commentTexts = Object.values(filterDeleted(note.comments)).map((comment) => comment.text.trim());
          //   const commentText = commentTexts.length > 1 ? commentTexts.join(' ✧ ') : commentTexts[0] || '';
          const el = contents.document.createElement(isMultiline ? 'div' : 'span');
          el.classList.add('inline-note', 'epubjs-ignore');
          const elId = `note-marker-${note.id}`;
          el.id = elId;
          el.onclick = (e) => {
            e.stopPropagation();
            clearSpan(note.id);
          };
          // Convert markdown to HTML
          el.innerHTML = (isMultiline ? marked.parse : marked.parseInline)(firstComment.text.trim(), {
            //breaks: isMultiline,
            gfm: true,
            async: false,
          });

          range.collapse(false);
          range.insertNode(el);
          const status = getNoteStatus(note.id);
          if (!status?.read) {
            setNoteStatus(note.id, { read: true, hidden: false });
          }
          redrawAnnotations();
          logger.debug('inserted el', el);
        }
      }
    }
  };

  const handleNoteSave = (updatedNote: Note) => {
    // const comments = { ...selectedNote?.comments, ...updatedNote.comments };
    const comments = updatedNote.comments || selectedNote?.comments || {};
    const newNote = { ...selectedNote, ...updatedNote, comments: comments };
    logger.debug('handleNoteSave', updatedNote);

    logger.debug('selectedNote =', selectedNote, ' newNote = ', newNote);
    setSelectedNote(newNote);
    if (newNote.color && book?.settings.lastColor !== newNote.color) {
      updateSettings({ lastColor: newNote.color });
    }

    const annotations = readerRef.current?.rendition?.annotations;
    bookRef.current &&
      annotations &&
      updateAnnotation(annotations, newNote, bookRef.current, readerRef.current.getIframeDoc());
    // noteOpenTimeoutRef.current = setTimeout(() => {
    // setSelectedNote(note);
    // Find the SVG element with data-range matching cfiRange
    // const gElement = document.querySelector(`svg > g[data-id="${newNote.id}"]`);
    // if (gElement) {
    //   setNoteAnchorEl(gElement);
    // } else {
    //   logger.error('no gElement for ', newNote);
    // }
    //   }, 150); // 300ms debounce
  };

  const deleteNote = (note: Note) => {
    setBook((prevBook) => {
      if (!prevBook || !prevBook.notes || Object.keys(prevBook.notes).length === 0) {
        // If there's no book or no notes, there's nothing to delete
        logger.error('No book or notes found.');
        return prevBook; // Return the unmodified state
      }

      // Filter out the note with the matching id
      const updatedNotes = {
        ...prevBook.notes,
        [note.id]: { ...note, deleted: true },
      };

      // Return the updated book object with the filtered notes array
      const newBook = {
        ...prevBook,
        notes: updatedNotes,
      };
      updateBook(newBook, true);
      const adjustedBook = getBook(newBook.id);
      if (adjustedBook) {
        delete adjustedBook.notes[note.id];
        return adjustedBook;
      } else {
        logger.error('adjustedBook not found');
        return prevBook;
      }
    });
    // book && updateBook(book);
    if (rendition?.annotations) {
      rendition.annotations.remove(note.cfiRange, 'highlight');
    }
    clearSpan(note.id);
    logger.debug('delete note', note, book);
  };

  const handleNoteDelete = () => {
    if (selectedNote) {
      deleteNote(selectedNote);
      setSelectedNote(null);
      readerRef.current?.getSelect()?.empty();
    }
  };

  const handleNoteClose = async () => {
    if (selectedNote) {
      setTimeout(() => {
        updateNote(selectedNote);
      }, 300);
    }

    setSelectedNote(null);
    readerRef.current?.getSelect()?.empty();
  };

  const checkWPM = useCallback(async () => {
    if ((totalWordCount || book?.wordCount) && book?.settings.updates && book?.settings.updates.length > 1) {
      const wpmTotal = await getTotalWPM(book);
      const wpmCurrent = calculateWPM(
        book.settings.updates,
        totalWordCount || book?.wordCount || 0,
        hoursForRecentWPM,
        10,
        wpmTotal
      );
      logger.debug('wpm:', wpmTotal, wpmCurrent);
      return [wpmCurrent, wpmTotal];
    }
    return [undefined, undefined];
  }, [totalWordCount, book?.wordCount, book?.settings.updates]);

  const updateWPM = useThrottle(async () => {
    const [current, total] = await checkWPM();
    setCurrentWPM(current);
    setOverallWPM(total);
  }, 30000);

  const handleRendered = () => {
    const rendition = readerRef.current?.rendition;
    if (!rendition) {
      logger.error('rendition not found in handleRendered');
      return;
    }
    const contents = (rendition.getContents() as any)[0] as Contents;
    if (contents) {
      if (contents !== sectionContents) {
        logger.info('section changed');
        doSettings();
        setSectionContents(contents);
      }
      const doc = contents.document.head.querySelector(
        `link[rel='stylesheet'][href='${import.meta.env.BASE_URL}rendition.css']`
      );
      if (!doc) {
        const link = contents.document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `${import.meta.env.BASE_URL}rendition.css`;
        contents.document.head.appendChild(link);
      }
      // contents.addStylesheet("/src/style/rendition.css");
      if (book?.settings.flow === 'scrolled') {
        contents.document.body.classList.add('scroll-flow');
      } else {
        contents.document.body.classList.remove('scroll-flow');
      }
    }
    logger.debug('handleRendered', rendition.location);
    setIsInitialized(true);

    if (!totalWordCount && rendition.book) {
      logger.debug('fetching total word count');
      rendition.book.ready.then(async () => {
        const [words, chapterWords] = await getTotalWordCount(rendition.book);
        setTotalWordCount(words);
        setChapterWordCount(chapterWords);
        // if (book) {
        //   updateBook({ ...book, wordCount: words, chapterWordCount: chapterWords });
        // }
        logger.debug('total word count:', words);
      });
    } else {
      logger.debug('rendition not ready?', rendition, book);
    }

    updateWPM();
    adjustFullscreenClass();
    applyFlowClass();
    applyImageInversion();
    checkAppVersion();
    setViewport();

    setTimeout(() => {
      redrawAnnotations();
    }, 300);

    // Wait for the iframe document to be ready before applying settings
    // const waitForIframe = async () => {
    //   return new Promise<void>((resolve) => {
    //     const checkIframe = () => {
    //       if (readerRef.current?.getIframeDoc()) {
    //         resolve();
    //       } else {
    //         afterLayout(() => {
    //           checkIframe();
    //         });
    //       }
    //     };
    //     checkIframe();
    //   });
    // };

    // let doc = readerRef.current?.getIframeDoc();
    //   if (!doc) {
    //     await waitForIframe();
    //     doc = readerRef.current?.getIframeDoc();
    //   }

    // if (isInitialized) {
    //   applySettings(rendition);
    // }
    // if (!isInitialized) {
    //   applySettings(rendition);
    //   setIsInitialized(true);
    // }

    // const wait = async () => {
    //     if (!bookRef.current?.settings) {
    //         return;
    //     }
    //     const initialStyle = createInitialStyle(bookRef.current.settings);
    // if (prefsRef.current?.theme) {
    //   applyThemeColors(rendition, prefsRef.current.theme as 'light' | 'dark');
    // }

    //     let doc = readerRef.current?.getIframeDoc();
    //     if (doc && bookRef.current && prefsRef.current) {
    //       logger.info('applying adjust');
    //       let adjustedStyle = await checkAndAdjustStyles(doc, bookRef.current.settings, prefsRef.current);
    //       const combinedStyle = serializeCSSObject({
    //         ...initialStyle,
    //         ...adjustedStyle,
    //         body: { ...initialStyle.body },
    //       });
    //       if (combinedStyle !== lastCombinedStyle) {
    //         setLastCombinedStyle(combinedStyle);
    //         readerRef.current?.rendition?.themes.registerCss('custom', combinedStyle);
    //         readerRef.current?.rendition?.themes.select('custom');
    //       }
    //     } else {
    //       logger.info('skipping, no doc');
    //     }
    //   };
    //   wait();
    // setTimeout(() => {
    //   readerRef.current?.rendition && applySettings(readerRef.current?.rendition);
    // }, 100);

    // applySettings?.(rendition);
    // const waitForIframe = async () => {
    // if (readerRef.current?.getIframeDoc()) {
    //   applySettings?.(rendition);
    //   //   } else {
    //   // requestAnimationFrame(waitForIframe);
    //   //   }
    // }
    // waitForIframe();
  };

  const applyImageInversion = () => {
    const urlCache = (readerRef.current?.book as any)?.archive.urlCache;
    const doc = readerRef.current?.getIframeDoc();
    id && urlCache && doc && invertGrayscaleImages(getTheme(prefs?.theme) === 'dark', id, urlCache, doc);
  };

  const handleResize = () => {
    if (isInitialized && !firstDisplayTimeRef.current) {
      logger.info('handleResize firing display');
      readerRef.current?.display(null);
    }
  };

  const handleDisplayed = () => {
    // setTimeout(() => {
    //   setIsBookReadyForUpdate(true);
    // }, 3000);
    // const maxAttempts = 20; // 3000ms / 100ms = 30 attempts
    // const checkLocation = () => {
    //   if (attempts.current >= maxAttempts) {
    //     logger.warn("Gave up waiting for rendition location");
    //     return;
    //   }
    //   const currentLocation = readerRef.current?.rendition?.location;
    //   if (currentLocation && book?.settings.location) {
    //     logger.debug("handleDisplayed - rendition location:", currentLocation.start.cfi, currentLocation.end.cfi);
    //     // if (!book?.settings.location.includes("epubcfi")) {
    //     //   logger.debug("handleDisplayed - book location is not epubcfi, setting isBookReadyForUpdate to true");
    //     //   setIsBookReadyForUpdate(true);
    //     //   attempts.current = 0;
    //     //   return;
    //     // }
    //     // Compare the current location with the saved location
    //     // const comparisonStart = EpubCFI.prototype.compare(currentLocation.start.cfi, book.settings.location);
    //     // const comparisonEnd = EpubCFI.prototype.compare(book.settings.location, currentLocation.end.cfi);
    //     // const comparisonStart =
    //     //   currentLocation.start.cfi === book.settings.location
    //     //     ? 0
    //     //     : currentLocation.start.cfi < book.settings.location
    //     //     ? -1
    //     //     : 1;
    //     // const comparisonEnd =
    //     //   book.settings.location === currentLocation.end.cfi
    //     //     ? 0
    //     //     : book.settings.location < currentLocation.end.cfi
    //     //     ? -1
    //     //     : 1;
    //     // if (
    //     //   (comparisonStart === 0 && attempts.current < 10) ||
    //     //   (attempts.current >= 10 && comparisonStart <= 0 && comparisonEnd <= 0)
    //     // ) {
    //     //   setIsBookReadyForUpdate(true);
    //     //   logger.debug("Current location is within the saved location range, setting isBookReadyForUpdate to true");
    //     attempts.current = maxAttempts;
    //     // } else {
    //     //   logger.debug(
    //     //     "Current location is outside the saved location range, displaying saved location of ",
    //     //     book.settings.location
    //     //   );
    //     //   attempts.current++;
    //     // setTimeout(() => {
    //     readerRef.current?.display(book.settings.location);
    //     // }, 250);
    //   } else {
    //     attempts.current++;
    //     setTimeout(checkLocation, 500);
    //   }
    // };
    // checkLocation();
  };

  const pushVisibleAt = (isVisible: boolean) => {
    const b = bookRef.current;
    if (!b) {
      return;
    }
    if (!b.visibleAt) {
      b.visibleAt = {};
    }
    b.visibleAt[Date.now()] = isVisible;
    updateBook({ ...b, visibleAt: b.visibleAt });
  };

  const handleUpdateAllNotes = (a: NotesGroup) => {
    logger.debug('handling update allNotes, a=', a);
    setAllNotes(a);
  };

  //   const handleUserActivation = useThrottle((isActive: boolean) => {
  //     if (isActive) {
  //       changeFullscreen(true);
  //     }
  //   }, 1000);

  return (
    <div
      style={
        {
          // top: "env(safe-area-inset-top)",
          // height: "calc(100vh - env(safe-area-inset-top))",
          // height: "calc(100vh - env(safe-area-inset-top))",
          // height: "100%",
          // height:
          //   (readerRef.current?.iframe?.documentElement.clientHeight || 20) -
          //     20 || "100vh",
        }
      }
    >
      {localForageLoaded && isStatusBarVisible && book && (
        <div style={readerStyles.titleArea}>
          <TopStatusBar
            onToggle={toggleDrawer}
            meta={book}
            getReader={() => readerRef.current}
            currentWPM={currentWPM}
            overallWPM={overallWPM}
            settings={book.settings}
            chapterProgress={chapterProgress}
            prefs={prefs || {}}
            updatePrefs={changePrefs}
            numLocations={readerRef.current?.book?.locations.length() || 0}
          />
        </div>
      )}

      <div style={readerStyles.container}>
        {id && book && (
          <div style={readerStyles.reader}>
            {selectedNote && (
              <NoteBox
                note={selectedNote}
                onClose={handleNoteClose}
                onSave={handleNoteSave}
                onDelete={handleNoteDelete}
                editable={!selectedNote?.owner || selectedNote?.owner === user?.email}
                setSnackbarMessage={setSnackbarMessage}
                getContextFunction={readerRef.current?.getSurroundingText}
                selection={readerRef.current?.state.selection}
              />
            )}
            <EpubView
              ref={readerRef}
              loadingView={loadingView === undefined ? <div style={readerStyles.loadingView}></div> : loadingView}
              location={book?.settings?.location}
              {...props}
              tocChanged={setToc}
              handleOnRelocated={handleOnRelocated}
              toggleStatusBar={handleToggleStatusBar}
              settings={book?.settings}
              addNote={addNote}
              meta={book}
              id={id}
              url={fileURL}
              getRendition={getRendition}
              textSelected={handleTextSelected}
              ignoreLocationChangeRef={ignoreLocationChangeRef}
              onMarkClicked={handleMarkClicked}
              onMarkLongPress={handleMarkLongPress}
              onDisplayed={handleDisplayed}
              onRendered={handleRendered}
              applySettings={applySettings}
              applyAnnotations={applyAnnotations}
              applyImageInversion={applyImageInversion}
              user={user}
              updateAllNotes={handleUpdateAllNotes}
              onScrolled={handleScrolled}
              toggleDrawer={toggleDrawer}
              redrawAnnotations={redrawAnnotations}
              setGeneratingLocations={setGeneratingLocations}
              generatingLocations={generatingLocations}
              prefs={prefs}
              onResize={handleResize}
              doSettings={doSettings}
              setIsInitialized={setIsInitialized}
              isNoteBoxOpen={Boolean(selectedNote)}
              ignoreNextTapRef={ignoreNextTapRef}
              setAnchors={setAnchors}
              setChapterLocations={setChapterLocations}
              isStatusBarVisible={isStatusBarVisible}
            />
          </div>
        )}

        <>
          {book && (
            <TemporaryDrawer
              open={isDrawerOpen}
              onClose={toggleDrawer}
              toc={toc}
              setLocation={setLocation}
              rendition={rendition}
              book={book}
              updateSettings={updateSettings}
              goToPercentage={goToPercentage}
              historyRef={historyRef}
              bookRef={bookRef}
              setUsedBack={setUsedBack}
              openTab={openTab}
              setOpenTab={setOpenTab}
              setSnackbarMessage={setSnackbarMessage}
              anchors={anchors}
              readerRef={readerRef}
              chapterLocations={chapterLocations}
            />
          )}
        </>
      </div>

      {isOverlappingDialogOpen && (
        <Dialog open={isOverlappingDialogOpen} onClose={() => setIsOverlappingDialogOpen(false)}>
          <DialogTitle style={{ textAlign: 'center' }}>Select note...</DialogTitle>
          <List>
            {overlappingNotes.map((note) => (
              <ListItemButton
                key={note.id}
                onClick={() => {
                  if (ignoreNextTapRef.current) {
                    logger.debug('ignoring tap in overlap onClick');
                    return;
                  }
                  setIsOverlappingDialogOpen(false);
                  handleSelectedNote(note);
                }}
              >
                <ListItemAvatar>
                  <Avatar src={getPerson(note.owner)?.picture} alt={getPerson(note.owner)?.given_name || 'Unknown'}>
                    {getPerson(note.owner)?.given_name}
                  </Avatar>
                </ListItemAvatar>
                <Stack direction='column'>
                  <ListItemText
                    primary={`${
                      note.text.length > 30 ? `${note.text.slice(0, 15)} ... ${note.text.slice(-15)}` : note.text
                    }`}
                    secondary={new Date(note.timestamp).toLocaleString()}
                  />
                  {countComments(note) > 0 && (
                    <ListItemText
                      //   primary={`${getPerson(note.owner)?.given_name || 'Unknown'}`}
                      secondary={` (${countComments(note)} comment${countComments(note) === 1 ? '' : 's'})`}
                    />
                  )}
                </Stack>
              </ListItemButton>
            ))}
          </List>
        </Dialog>
      )}
    </div>
  );
};
